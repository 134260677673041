import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { ValueAddedBenefitsProperties } from "@components/Industries/ValueAddedBenefits/ValueAddedBenefits";
import { HeroProps } from "@components/Industries/Hero/Hero";
import { CustomizedMetalSolutionsProps } from "@components/Industries/CustomizedMetalSolutions/CustomizedMetalSolutions";
import { MetaContent } from "@components/Shared/model/MetaHelmet";
import { RelatedSolutionsCapabilitiesAndProductsProperties } from "@components/Industries/RelatedSolutionsCapabilitiesAndProducts/RelatedSolutionsCapabilitiesAndProducts";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Comprehensive Metal Solutions for Aerospace, Defense, and Semiconductor Industries | Ryerson",
		description:
			"Discover how Ryerson, alongside Production Metals, delivers tailored metal solutions for the aerospace, defense, and semiconductor sectors. With specialized expertise and advanced processing capabilities, we meet the unique material and certification needs of high-performance industries. Explore our innovative services today!",
		url: "/metal-solutions/industries/aerospace-defense-semiconductor",
		imageUrl: "/images/industries/AerospaceDefenseSemicondutor.jpg",
		company: true,
	},
	fr: {
		title: "Comprehensive Metal Solutions for Aerospace, Defense, and Semiconductor Industries | Ryerson",
		description:
			"Discover how Ryerson, alongside Production Metals, delivers tailored metal solutions for the aerospace, defense, and semiconductor sectors. With specialized expertise and advanced processing capabilities, we meet the unique material and certification needs of high-performance industries. Explore our innovative services today!",
		url: "/metal-solutions/industries/aerospace-defense-semiconductor",
		imageUrl: "/images/industries/AerospaceDefenseSemicondutor.jpg",
		company: true,
	},
	es: {
		title: "Comprehensive Metal Solutions for Aerospace, Defense, and Semiconductor Industries | Ryerson",
		description:
			"Discover how Ryerson, alongside Production Metals, delivers tailored metal solutions for the aerospace, defense, and semiconductor sectors. With specialized expertise and advanced processing capabilities, we meet the unique material and certification needs of high-performance industries. Explore our innovative services today!",
		url: "/metal-solutions/industries/aerospace-defense-semiconductor",
		imageUrl: "/images/industries/AerospaceDefenseSemicondutor.jpg",
		company: true,
	},
};

export const HeroContent: LanguageContent<HeroProps> = {
	en: {
		heroTitle: "Aerospace, Defense, and Semiconductor",
		heroDescription: [
			`Ryerson delivers comprehensive solutions for the aerospace, defense, and semiconductor industries, addressing their distinct material, certification, and processing needs.`,
			`Production Metals, part of The Ryerson Family of Companies, brings specialized industry expertise that, when paired with Ryerson’s expansive distribution network and advanced processing capabilities, delivers tailored metal solutions for these critical sectors. This collaboration provides an unparalleled blend of advanced fabrication, strategic material staging, and scalable solutions to meet the demands of high-performance industries.`,
		],
		buttonLabel: "Contact a Professional",
		hasVideo: false,
		heroVideoId: "",
		heroImageUrl: "/images/industries/AerospaceDefenseSemiconductorPageImage.jpg",
		isWatermarkHero: true,
		watermarkUrl: "/images/industries/AerospaceDefenseSemiconductorWatermark.jpg",
	},
	fr: {
		heroTitle: "Aérospatiale, défense et semi-conducteurs",
		heroDescription: [
			`Ryerson offre des solutions complètes aux industries de l’aérospatiale, de la défense et des semi-conducteurs, en répondant à leurs besoins distincts en matière de matériaux, de certification et de traitement.`,
			`Production Metals, qui fait partie de la famille d’entreprises de Ryerson, apporte une expertise industrielle spécialisée qui, associée au vaste réseau de distribution de Ryerson et à ses capacités de traitement avancées, permet de fournir des produits métallurgiques personnalisés pour ces secteurs critiques. Cette collaboration offre une alliance inégalée de fabrication avancée, d’entreposage stratégique des matériaux et de solutions évolutives pour répondre aux exigences des industries de haute performance.`,
		],
		buttonLabel: "Prenez contact avec un professionnel",
		hasVideo: false,
		heroVideoId: "",
		heroImageUrl: "/images/industries/AerospaceDefenseSemiconductorPageImage.jpg",
		isWatermarkHero: true,
		watermarkUrl: "/images/industries/AerospaceDefenseSemiconductorWatermark.jpg",
	},
	es: {
		heroTitle: "Aeroespacial, defensa y semiconductores",
		heroDescription: [
			`Ryerson ofrece soluciones completas para las industrias aeroespacial, de defensa y semiconductores, atendiendo sus necesidades definidas de materiales, certificación y procesamiento.`,
			`Production Metals, parte de la Familia de compañías Ryerson, ofrece una experiencia industrial especializada que, al acompañarse con la amplia red de distribución y las capacidades avanzadas de procesamiento de Ryerson, proporciona soluciones de metales personalizadas para estos sectores críticos. Esta colaboración brinda una combinación inigualable de fabricación avanzada, transporte estratégico de materiales y soluciones escalables para satisfacer las demandas de las industrias de alto desempeño.`,
		],
		buttonLabel: "Póngase en contacto con un profesional ",
		hasVideo: false,
		heroVideoId: "",
		heroImageUrl: "/images/industries/AerospaceDefenseSemiconductorPageImage.jpg",
		isWatermarkHero: true,
		watermarkUrl: "/images/industries/AerospaceDefenseSemiconductorWatermark.jpg",
	},
};

export const ValueAddedBenefitsContent: LanguageContent<ValueAddedBenefitsProperties> = {
	en: {
		title: "Value-Added Benefits",
		mainDescription: `Ryerson's fabrication and processing capabilities are tailored to meet the rigorous demands of the aerospace, defense, and semiconductor industries. Ryerson offers water jet cutting, laser cutting, flame cutting, 3D manufacturing, and more, all with precision and consistency for critical applications.`,
		buttonLabel: "Let's Talk!",
		secondaryContent: [
			{
				title: "Advanced Processing",
				description: `When you need solutions to complex challenges, our team offers more than just a list of fabrication capabilities. Fully invested in your success, we evaluate each step of the process and offer custom solutions focused on saving you time and money.`,
				imageUrl: "/images/homepage/solutions-we-offer/AdvancedProcessing.jpg",
				showImageOnDesktop: true,
				showImageOnMobile: true,
			},
			{
				title: "Additive Manufacturing",
				description: `Additive manufacturing can reduce product times and cost associated with small parts production.`,
				imageUrl: "/images/metal-solutions/solutions/AdditiveManufacturing.png",
				showImageOnDesktop: true,
				showImageOnMobile: true,
			},
		],
	},
	fr: {
		title: "Avantages à valeur ajoutée",
		mainDescription: `Les capacités de fabrication et de traitement de Ryerson sont conçues pour répondre aux exigences rigoureuses des industries de l’aérospatiale, de la défense et des semi-conducteurs. Ryerson propose la découpe au jet d’eau, la découpe au laser, l’oxycoupage, l’impression 3D et bien plus encore, le tout avec précision et uniformité pour les applications critiques.`,
		buttonLabel: "Discutons",
		secondaryContent: [
			{
				title: "Transformation évoluée",
				description: `Lorsque vous avez besoin de solutions répondant à des défis complexes, notre équipe propose plus qu’une simple liste de capacités de fabrication. Nous avons à cœur votre succès. C’est pourquoi nous évaluons chaque étape du processus et vous offrons des solutions personnalisées pour vous faire économiser du temps et de l’argent.`,
				imageUrl: "/images/homepage/solutions-we-offer/AdvancedProcessing.jpg",
				showImageOnDesktop: true,
				showImageOnMobile: true,
			},
			{
				title: "Fabrication additive",
				description: `La fabrication additive permet de réduire les délais et les coûts liés à la production des petites pièces.`,
				imageUrl: "/images/metal-solutions/solutions/AdditiveManufacturing.png",
				showImageOnDesktop: true,
				showImageOnMobile: true,
			},
		],
	},
	es: {
		title: "Beneficios de valor agregado",
		mainDescription: `Las capacidades de fabricación y procesamiento de Ryerson están personalizadas para satisfacer las rigurosas demandas de las industrias aeroespacial, de defensa y semiconductores. Ryerson ofrece corte con chorro de agua, corte con flama, fabricación 3D y más, todo con precisión y consistencia para aplicaciones críticas.`,
		buttonLabel: "Hablemos",
		secondaryContent: [
			{
				title: "Procesamiento avanzado",
				description: `Cuando necesita soluciones para retos difíciles, nuestro equipo le ofrece mucho más que una simple lista de capacidades de fabricación. Totalmente comprometidos con su éxito, evaluamos todos los pasos del proceso y ofrecemos soluciones personalizadas pensadas para que ahorre tiempo y dinero.`,
				imageUrl: "/images/homepage/solutions-we-offer/AdvancedProcessing.jpg",
				showImageOnDesktop: true,
				showImageOnMobile: true,
			},
			{
				title: "Fabricación aditiva",
				description: `La fabricación aditiva puede reducir los tiempos y los costos de producción asociados a la fabricación de partes pequeñas.`,
				imageUrl: "/images/metal-solutions/solutions/AdditiveManufacturing.png",
				showImageOnDesktop: true,
				showImageOnMobile: true,
			},
		],
	},
};

export const CustomizedMetalSolutionsContent: LanguageContent<CustomizedMetalSolutionsProps> = {
	en: {
		sectionHeading: "Customized Metal Solutions for",
		title: "Aerospace, Defense, and Semiconductor",
		mainDescription: `Ryerson's extensive network and access to specialty metals ensure you get the critical materials you need—fast. From high-performance alloys for aerospace to ultra-pure metals for semiconductors, we source from approved suppliers and strategically stage inventory nationwide, delivering reliable solutions with unmatched speed and efficiency.`,
		contact: {
			heading: "Contact your Aerospace, Defense, and Semiconductor Professional",
			button: "Contact a Professional",
			name: "Amanda Seabaugh",
			title: "Sr Director - Vertical Markets",
			email: "Amanda.Seabaugh@ryerson.com",
			portrait: "/images/industries/distribution-systems/AmandaSeabaugh.jpg",
		},
		thinglink: {
			description: `Ryerson has a team dedicated to aerospace, defense, and semiconductors. They are actively solving problems for companies like yours by delivering the material and designing a supply chain that addresses a range of needs.`,
			id: "1921318608869261989",
			width: 960,
			height: 640.0766100071822,
			originalHeight: 2785,
			originalWidth: 4177,
		},
	},
	fr: {
		sectionHeading: "Des solutions métallurgiques personnalisées pour",
		title: "l'industrie de l'érospatiale, de la défense et des semi-conducteurs",
		mainDescription: `Le vaste réseau de Ryerson et son accès aux métaux spécialisés vous assurent d’obtenir rapidement les matériaux essentiels dont vous avez besoin. Qu’il s’agisse d’alliages à haute performance pour l’aérospatiale ou de métaux ultra-purs pour les semi-conducteurs, nous nous approvisionnons auprès de fournisseurs approuvés et nous entreposons stratégiquement nos stocks à travers le pays, afin de fournir des solutions fiables avec une rapidité et une efficacité inégalées.`,
		contact: {
			heading: "Prenez contact avec un professionnel",
			button: "Communiquer avec un expert",
			name: "Amanda Seabaugh",
			title: "Sr Director - Vertical Markets",
			email: "Amanda.Seabaugh@ryerson.com",
			portrait: "/images/industries/distribution-systems/AmandaSeabaugh.jpg",
		},
		thinglink: {
			description: `Des métaux et des services spécialisés pour répondre aux défis de taille de certaines des industries les plus exigeantes.`,
			id: "1921318608869261989",
			width: 960,
			height: 640.0766100071822,
			originalHeight: 2785,
			originalWidth: 4177,
		},
	},
	es: {
		sectionHeading: "Soluciones de metal personalizadas para",
		title: "aeroespacial, defensa y semiconductores",
		mainDescription: `La amplia red y los metales de especialidad de Ryerson aseguran que reciba los materiales críticos que necesita, rápido. Desde aleaciones de alto desempeño para la industria aeroespacial, hasta metales ultra puros para semiconductores, adquirimos los materiales de proveedores aprobados y transportamos estratégicamente el inventario a nivel nacional, proporcionando soluciones confiables con velocidad y eficiencia inigualables.`,
		contact: {
			heading: "Póngase en contacto con un profesional",
			button: "Ponerse en contacto con un experto",
			name: "Amanda Seabaugh",
			title: "Sr Director - Vertical Markets",
			email: "Amanda.Seabaugh@ryerson.com",
			portrait: "/images/industries/distribution-systems/AmandaSeabaugh.jpg",
		},
		thinglink: {
			description: `Metales y servicios especializados para cumplir con los rigurosos desafíos de algunas de las industrias más demandantes.`,
			id: "1921318608869261989",
			width: 960,
			height: 640.0766100071822,
			originalHeight: 2785,
			originalWidth: 4177,
		},
	},
};

export const RelatedSolutionsCapabilitiesContent: LanguageContent<RelatedSolutionsCapabilitiesAndProductsProperties> =
	{
		en: {
			title: "Related Solutions, Capabilities, and Products",
			mainDescription: `Meet stringent quality and certification standards required by the aerospace, defense, and semiconductor industries. Ryerson is committed to ensuring every material meets the highest levels of safety and reliability.`,
			secondaryContent: [
				{
					title: "Solutions & Capabilities",
					jumpToLabel: "More Metal Solutions",
					jumpToLink: "/metal-solutions/",
					bullets: [
						{
							title: "Tube Laser",
							iconType: "capabilities-tube-laser",
							link: "/metal-solutions/capabilities/bar-tube-structural-processing/tube-laser",
						},
						{
							title: "Bar, Tube, Structural Processing",
							link: "/metal-solutions/capabilities/bar-tube-structural-processing/",
							iconType: "solution-bar-tube-structural-processing",
						},
						{
							title: "Plate Processing",
							link: "/metal-solutions/capabilities/plate-processing/",
							iconType: "solution-fabrication",
						},
					],
				},
				{
					title: "Products",
					jumpToLabel: "More Metal Products",
					jumpToLink: "/store",
					bullets: [
						{
							title: "Aluminum Sheet",
							imageUrl: "/static-assets/images/renderings/AluminumSheet.png",
							link: "/aluminum/aluminum-sheet",
						},
						{
							title: "Steel Sheet",
							imageUrl: "/static-assets/images/renderings/CarbonPlate.png",
							link: "/steel/steel-sheet",
						},

						{
							title: "Stainless Bar",
							imageUrl: "/static-assets/images/renderings/StainlessBarRound.png",
							link: "/stainless-steel/bar/round",
						},
					],
				},
			],
		},
		fr: {
			title: "Solutions, capacités et produits connexes",
			mainDescription: `Répondez aux normes rigoureuses de qualité et de certification exigées par les industries de l’aérospatiale, de la défense et des semi-conducteurs. Ryerson s’engage à ce que chaque matériau réponde aux niveaux les plus élevés de sécurité et de fiabilité.`,
			secondaryContent: [
				{
					title: "Solutions et capacités",
					jumpToLabel: "Plus de solutions métallurgiques et de capacités",
					jumpToLink: "/metal-solutions/",
					bullets: [
						{
							title: "Laser de découpe de tubes",
							iconType: "capabilities-tube-laser",
							link: "/metal-solutions/capabilities/bar-tube-structural-processing/tube-laser",
						},
						{
							title: "Traitement de barres, de tubes et de formes structurales",
							link: "/metal-solutions/capabilities/bar-tube-structural-processing/",
							iconType: "solution-bar-tube-structural-processing",
						},
						{
							title: "Traitement de plaques",
							link: "/metal-solutions/capabilities/plate-processing/",
							iconType: "solution-fabrication",
						},
					],
				},
				{
					title: "Produits",
					jumpToLabel: "Plus de produits métalliques",
					jumpToLink: "/store",
					bullets: [
						{
							title: "Feuille d'aluminium",
							imageUrl: "/static-assets/images/renderings/AluminumSheet.png",
							link: "/aluminum/aluminum-sheet",
						},
						{
							title: "Feuille d'acier",
							imageUrl: "/static-assets/images/renderings/CarbonPlate.png",
							link: "/steel/steel-sheet",
						},

						{
							title: "Barre en acier inoxydable",
							imageUrl: "/static-assets/images/renderings/StainlessBarRound.png",
							link: "/stainless-steel/bar/round",
						},
					],
				},
			],
		},
		es: {
			title: "Soluciones, capacidades y productos relacionados",
			mainDescription: `Cumpla con los estrictos estándares de calidad y certificación requeridos por las industrias aeroespacial, de defensa y semiconductores. Ryerson está comprometida con asegurar que cada material cumpla con los niveles más altos de seguridad y confiabilidad.`,
			secondaryContent: [
				{
					title: "Soluciones y capacidades",
					jumpToLabel: "Más soluciones y capacidades de metal",
					jumpToLink: "/metal-solutions/",
					bullets: [
						{
							title: "Corte con láser de tubos",
							iconType: "capabilities-tube-laser",
							link: "/metal-solutions/capabilities/bar-tube-structural-processing/tube-laser",
						},
						{
							title: "Procesamiento de barras, tubos y estructuras",
							link: "/metal-solutions/capabilities/bar-tube-structural-processing/",
							iconType: "solution-bar-tube-structural-processing",
						},
						{
							title: "Procesamiento de placas",
							link: "/metal-solutions/capabilities/plate-processing/",
							iconType: "solution-fabrication",
						},
					],
				},
				{
					title: "Productos",
					jumpToLabel: "Más productos de metal",
					jumpToLink: "/store",
					bullets: [
						{
							title: "Hoja de Aluminio",
							imageUrl: "/static-assets/images/renderings/AluminumSheet.png",
							link: "/aluminum/aluminum-sheet",
						},
						{
							title: "Hoja de acero",
							imageUrl: "/static-assets/images/renderings/CarbonPlate.png",
							link: "/steel/steel-sheet",
						},

						{
							title: "Barra de acero inoxidable",
							imageUrl: "/static-assets/images/renderings/StainlessBarRound.png",
							link: "/stainless-steel/bar/round",
						},
					],
				},
			],
		},
	};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [
		HeroContent,
		ValueAddedBenefitsContent,
		RelatedSolutionsCapabilitiesContent,
		CustomizedMetalSolutionsContent,
	],
};
