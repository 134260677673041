import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { MetaContent } from "@components/Shared/model/MetaHelmet";
import { GrowHeroContent } from "@components/GrowWithSales/Hero/Hero";
import { SolutionsWeOfferContent } from "@components/Solutions/ParallaxSolutionsWeOffer/SolutionsWeOffer";
import { ExploreOtherCapabilitiesSolutionsProps } from "@components/BarTubeStructuralProcessing/ExploreOtherCapabilitiesSolutions/ExploreOtherCapabilitiesSolutions";
import { TextImageSlide } from "@components/BarTubeStructuralProcessing/TextImageSlide/TextImageSlide";
import { LanguageContent } from "@components/Shared/model/LanguageContent";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Comprehensive Value-Added Services for Metal Solutions | Ryerson",
		description:
			"Discover how Ryerson enhances your manufacturing process with tailored scrap management programs, optimized production parts, and a commitment to uncovering value at every step. Explore our extensive range of value-added services designed to improve efficiency and reduce costs.",
		url: `/metal-solutions/solutions/services`,
		imageUrl: "/images/metal-solutions/solutions/services/services-hero.jpg",
		company: true,
	},
	fr: {
		title: "Comprehensive Value-Added Services for Metal Solutions | Ryerson",
		description:
			"Discover how Ryerson enhances your manufacturing process with tailored scrap management programs, optimized production parts, and a commitment to uncovering value at every step. Explore our extensive range of value-added services designed to improve efficiency and reduce costs.",
		url: `/metal-solutions/solutions/services`,
		imageUrl: "/images/metal-solutions/solutions/services/services-hero.jpg",
		company: true,
	},
	es: {
		title: "Comprehensive Value-Added Services for Metal Solutions | Ryerson",
		description:
			"Discover how Ryerson enhances your manufacturing process with tailored scrap management programs, optimized production parts, and a commitment to uncovering value at every step. Explore our extensive range of value-added services designed to improve efficiency and reduce costs.",
		url: `/metal-solutions/solutions/services`,
		imageUrl: "/images/metal-solutions/solutions/services/services-hero.jpg",
		company: true,
	},
};

export const HeroContent: LanguageContent<GrowHeroContent> = {
	en: {
		title: "Services",
		subTitle: "The value we provide to you goes well beyond the initial transaction.",
		description: `From implementing scrap management programs to re-engineering parts for optimized production, Ryerson delivers a wide range of value-added services that go beyond just metal. We're dedicated to uncovering value at every step of the process.`,
		thumbnailUrl: "/images/metal-solutions/solutions/services/services-hero.jpg",
		buttonLabel: "Contact Us",
		buttonUrl: "/contact-us",
	},
	fr: {
		title: "Services",
		subTitle: "La valeur que nous vous offrons va bien au-delà de la transaction initiale.",
		description: `De la mise en œuvre de programmes de gestion de la ferraille à la restructuration des pièces pour une production optimisée, Ryerson offre une large gamme de services à valeur ajoutée qui vont au-delà du simple métal. Nous sommes déterminés à découvrir de la valeur à chaque étape du processus.`,
		thumbnailUrl: "/images/metal-solutions/solutions/services/services-hero.jpg",
		buttonLabel: "Communiquer Avec Nous",
		buttonUrl: "/contact-us",
	},
	es: {
		title: "Servicios",
		subTitle: "El valor que le proporcionamos va más allá de la transacción inicial.",
		description: `Desde implementar programas de manejo de residuos hasta rediseñar partes para fabricación optimizada, Ryerson proporciona una amplia gama de servicios de valor agregado que van más allá de solo metales. Nos dedicamos a descubrir el valor en cada parte del proceso.`,
		thumbnailUrl: "/images/metal-solutions/solutions/services/services-hero.jpg",
		buttonLabel: "Contáctenos",
		buttonUrl: "/contact-us",
	},
};

export const EquipmentAndProcessContent: LanguageContent<TextImageSlide> = {
	en: {
		title: "Value at Every Point",
		navLabel: "Value at Every Point",
		sections: [
			{
				title: "Value Engineering",
				imageUrl: "/images/metal-solutions/solutions/services/value-engineering.jpg",
				description:
					"Our metal professionals can help you solve some of your most critical parts design or material utilization methods. We have the resources to help you lower costs, improve quality, and deliver impressive results to your end customers.",
				actionText: "Let's Discuss Your Project",
				actionLink: "/contact-us",
			},
			{
				title: "Scrap Management",
				imageUrl: "/images/metal-solutions/solutions/services/scrap-management.jpg",
				description:
					"Ryerson is well versed in scrap management and can help you develop a successful scrap program for your material. Whether you are looking for some simple in-house tips on maximizing your return, or you would like us to introduce you to partners to coordinate a full review, Ryerson can help.",
				actionText: "Let's Discuss Your Project",
				actionLink: "/contact-us",
			},
			{
				title: "Logistics",
				imageUrl: "/images/metal-solutions/solutions/services/logistics.jpg",
				description:
					"From start to finish, we'll manage logistics across all phases of the value chain, customizing our services to provide the optimal resources at the right time for the right price. Our specialized services are designed to help lower secondary vendor costs and increase efficiency.",
				actionText: "Let's Discuss Your Project",
				actionLink: "/contact-us",
			},
		],
	},
	fr: {
		title: "Une valeur à chaque étape",
		navLabel: "Une valeur à chaque étape",
		sections: [
			{
				title: "Ingénierie de la valeur",
				imageUrl: "/images/metal-solutions/solutions/services/value-engineering.jpg",
				description:
					"Nos professionnels des métaux peuvent vous aider à résoudre certaines de vos méthodes les plus critiques en matière de conception de pièces ou d’utilisation des matériaux. Nous disposons des ressources nécessaires pour vous aider à réduire les coûts, à améliorer la qualité et à fournir des résultats impressionnants à vos clients finaux.",
				actionText: "Discutons de votre projet",
				actionLink: "/contact-us",
			},
			{
				title: "Gestion de la ferraille",
				imageUrl: "/images/metal-solutions/solutions/services/scrap-management.jpg",
				description:
					"Ryerson connaît bien la gestion de la ferraille et peut vous aider à développer un programme de gestion de la ferraille efficace pour vos matériaux. Que vous soyez à la recherche de simples conseils internes pour maximiser votre rendement ou que vous souhaitiez que nous vous présentions des partenaires pour coordonner une évaluation complète, Ryerson peut vous aider.",
				actionText: "Discutons de votre projet",
				actionLink: "/contact-us",
			},
			{
				title: "Logistique",
				imageUrl: "/images/metal-solutions/solutions/services/logistics.jpg",
				description:
					"Du début à la fin, nous prenons en charge la logistique à toutes les étapes de la chaîne de valeur, en personnalisant nos services afin de fournir les ressources optimales au bon moment et au bon prix. Nos services spécialisés sont conçus pour réduire les coûts des fournisseurs secondaires et accroître l’efficacité.",
				actionText: "Discutons de votre projet",
				actionLink: "/contact-us",
			},
		],
	},
	es: {
		title: "Valor en cada punto",
		navLabel: "Valor en cada punto",
		sections: [
			{
				title: "Ingeniería con valor",
				imageUrl: "/images/metal-solutions/solutions/services/value-engineering.jpg",
				description:
					"Nuestros profesionales de la metalurgia pueden ayudarle a resolver algunos de sus diseños de partes o métodos de uso de material críticos. Tenemos los recursos para ayudarle a reducir los costos, mejorar la calidad y ofrecer resultados impresionantes para sus clientes finales.",
				actionText: "Hablemos de su proyecto",
				actionLink: "/contact-us",
			},
			{
				title: "Manejo de residuos",
				imageUrl: "/images/metal-solutions/solutions/services/scrap-management.jpg",
				description:
					"Ryerson conoce bien el manejo de residuos y puede ayudarle a desarrollar un programa exitoso de manejo de residuos para su material. Ya sea que esté buscando algunos consejos internos simples para maximizar sus ganancias o que desee que le presentemos a los socios para coordinar una revisión completa, Ryerson puede ayudar.",
				actionText: "Hablemos de su proyecto",
				actionLink: "/contact-us",
			},
			{
				title: "Logística",
				imageUrl: "/images/metal-solutions/solutions/services/logistics.jpg",
				description:
					"De inicio a fin, gestionaremos la logística en todas las etapas de la cadena de valor, personalizando nuestros servicios para ofrecer los recursos óptimos en el momento correcto y al precio correcto. Nuestros servicios especializados están diseñados para ayudar a reducir los costos de proveedores secundarios e incrementar la eficiencia.",
				actionText: "Hablemos de su proyecto",
				actionLink: "/contact-us",
			},
		],
	},
};

export const WhoDoWeHelpContent: LanguageContent<SolutionsWeOfferContent> = {
	en: {
		title1: "Who Do We Help?",
		title2: " ",
		title: "Who Do We Help",
		actionUrl: "/metal-solutions/solutions",
		actionLabel: "Learn More",
		description: `Companies producing world-class products have one critical need: the right metal at the right moment. At Ryerson, we don't take that responsibility lightly. It's why thousands of companies across hundreds of markets turn to us for their metal needs.`,
		buttonLabel: "Lets Talk!",
		buttonUrl: "/contact-us",
		cards: [
			{
				icon: "solution-advanced-processing",
				text: "Advanced \n Processing",
				backgroundUrl: "/images/metal-solutions/AdvancedProcessingStrip.jpg",
				link: "/metal-solutions/solutions/advanced-processing",
			},
			{
				icon: "solution-carbon-sheet-solutions",
				text: "Risk \n Management",
				backgroundUrl: "/images/metal-solutions/solutions/risk-chess.jpg",
				link: "/metal-solutions/solutions/risk-management",
			},
			{
				icon: "solution-global-accounts",
				text: "Additive \n Manufacturing",
				backgroundUrl: "/images/metal-solutions/solutions/additive-manufacturing-slice.jpg",
				link: "/metal-solutions/solutions/additive-manufacturing",
			},
			{
				icon: "solution-global-accounts",
				text: "Global \n Accounts",
				backgroundUrl: "/images/metal-solutions/GlobalAccountsStrip.jpg",
				link: "/metal-solutions/solutions/global-accounts",
			},
		],
	},
	fr: {
		title1: "Qui aidons-nous?",
		title2: "",
		title: "Qui aidons-nous",
		actionUrl: "/metal-solutions/solutions",
		actionLabel: "Apprendre encore plus",
		description: `Les entreprises produisant des produits de classe mondiale ont un besoin essentiel : le bon métal au bon moment. Chez Ryerson, nous ne prenons pas cette responsabilité à la légère. C'est pourquoi des milliers d'entreprises sur des centaines de marchés se tournent vers nous pour leurs besoins en métaux.`,
		buttonLabel: "Discutons",
		buttonUrl: "/contact-us",
		cards: [
			{
				icon: "solution-advanced-processing",
				text: "Traitement \n avancé",
				backgroundUrl: "/images/metal-solutions/AdvancedProcessingStrip.jpg",
				link: "/metal-solutions/solutions/advanced-processing",
			},
			{
				icon: "solution-carbon-sheet-solutions",
				text: "Gestion du \n risque",
				backgroundUrl: "/images/metal-solutions/solutions/risk-chess.jpg",
				link: "/metal-solutions/solutions/risk-management",
			},
			{
				icon: "solution-global-accounts",
				text: "Fabrication additive",
				backgroundUrl: "/images/metal-solutions/solutions/additive-manufacturing-slice.jpg",
				link: "/metal-solutions/solutions/additive-manufacturing",
			},
			{
				icon: "solution-global-accounts",
				text: "Comptes \n internationaux",
				backgroundUrl: "/images/metal-solutions/GlobalAccountsStrip.jpg",
				link: "/metal-solutions/solutions/global-accounts",
			},
		],
	},
	es: {
		title1: "¿A quién ayudamos?",
		title2: "",
		title: "¿A quién ayudamos",
		actionUrl: "/metal-solutions/solutions",
		actionLabel: "Aprende más",
		description: `Las empresas que fabrican productos de clase mundial tienen una necesidad fundamental: el metal adecuado en el momento adecuado. En Ryerson, no tomamos esa responsabilidad a la ligera. Es por eso que miles de empresas en cientos de mercados recurren a nosotros para sus necesidades de metales.`,
		buttonLabel: "Hablemos",
		buttonUrl: "/contact-us",
		cards: [
			{
				icon: "solution-advanced-processing",
				text: "Procesamiento \n avanzado",
				backgroundUrl: "/images/metal-solutions/AdvancedProcessingStrip.jpg",
				link: "/metal-solutions/solutions/advanced-processing",
			},
			{
				icon: "solution-carbon-sheet-solutions",
				text: "Gestión de \n riesgos",
				backgroundUrl: "/images/metal-solutions/solutions/risk-chess.jpg",
				link: "/metal-solutions/solutions/risk-management",
			},
			{
				icon: "solution-global-accounts",
				text: "Fabricación aditiva",
				backgroundUrl: "/images/metal-solutions/solutions/additive-manufacturing-slice.jpg",
				link: "/metal-solutions/solutions/additive-manufacturing",
			},
			{
				icon: "solution-global-accounts",
				text: "Cuentas \n globales",
				backgroundUrl: "/images/metal-solutions/GlobalAccountsStrip.jpg",
				link: "/metal-solutions/solutions/global-accounts",
			},
		],
	},
};

export const ExploreOtherCapabilitiesSolutionsContent: LanguageContent<ExploreOtherCapabilitiesSolutionsProps> =
	{
		en: {
			title: "Explore other Capabilities and Solutions",
			section: [
				{
					imageUrl: "/images/metal-solutions/explore-images/Fabrication.jpg",
					mainText: "Fabrication",
					subText: `From precision cutting to punching, bending and welding, we have the capabilities to transform metal into what you need.`,
					badgeText: "Capabilities",
					badgeColor: "other",
					linkUrl: "/metal-solutions/capabilities/fabrication/",
				},
				{
					imageUrl:
						"/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
					mainText: "Advanced Processing",
					subText:
						"We have the ability to produce engineered-to-order parts that meet your exact specifications. Our facilities have diverse capabilities, providing quick service and specialized processing.",
					badgeText: "Solution",
					badgeColor: "error",
					linkUrl: "/metal-solutions/solutions/advanced-processing",
				},
			],
			learnMoreText: "Learn more about our Metal Solutions",
			learnMoreUrl: "/metal-solutions",
		},
		fr: {
			title: "Parcourir les autres capacités et solutions",
			section: [
				{
					imageUrl: "/images/metal-solutions/explore-images/Fabrication.jpg",
					mainText: "Fabrication",
					subText: `De la découpe de précision au poinçonnage, au pliage et au soudage, nous avons les capacités de transformer le métal en ce dont vous avez besoin.`,
					badgeText: "Capacité",
					badgeColor: "other",
					linkUrl: "/metal-solutions/capabilities/fabrication/",
				},
				{
					imageUrl:
						"/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
					mainText: "Traitement avancé",
					subText:
						"Nous avons la capacité de produire des pièces conçues sur commande qui répondent exactement à vos spécifications. Nos installations ont des capacités diverses, offrant un service rapide et un traitement spécialisé.",
					badgeText: "Solution",
					badgeColor: "error",
					linkUrl: "/metal-solutions/solutions/advanced-processing/",
				},
			],
			learnMoreText: "En savoir plus sur nos solutions métalliques",
			learnMoreUrl: "/metal-solutions",
		},
		es: {
			title: "Explore otras capacidades y soluciones",
			section: [
				{
					imageUrl: "/images/metal-solutions/explore-images/Fabrication.jpg",
					mainText: "Fabricación",
					subText: `De la découpe de précision au poinçonnage, au pliage et au soudage, nous avons les capacités de transformer le métal en ce dont vous avez besoin.`,
					badgeText: "Capacidad ",
					badgeColor: "other",
					linkUrl: "/metal-solutions/capabilities/fabrication/",
				},
				{
					imageUrl:
						"/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
					mainText: "Procesamiento avanzado",
					subText:
						"Tenemos la habilidad de producir partes diseñadas conforme al pedido que cumplan con sus especificaciones exactas. Nuestras instalaciones tienen diversas capacidades, proporcionando servicio rápido y procesamiento especializado.",
					badgeText: "Solución",
					badgeColor: "error",
					linkUrl: "/metal-solutions/solutions/advanced-processing/",
				},
			],
			learnMoreText: "Obtenga más información sobre nuestras soluciones de metal",
			learnMoreUrl: "/metal-solutions",
		},
	};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [
		HeroContent,
		EquipmentAndProcessContent,
		WhoDoWeHelpContent,
		ExploreOtherCapabilitiesSolutionsContent,
	],
};
