import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { MetaContent } from "@components/Shared/model/MetaHelmet";
import { GrowHeroContent } from "@components/GrowWithSales/Hero/Hero";
import { SolutionsWeOfferContent } from "@components/Solutions/ParallaxSolutionsWeOffer/SolutionsWeOffer";
import { ExploreOtherCapabilitiesSolutionsProps } from "@components/BarTubeStructuralProcessing/ExploreOtherCapabilitiesSolutions/ExploreOtherCapabilitiesSolutions";
import { TextImageSlide } from "@components/BarTubeStructuralProcessing/TextImageSlide/TextImageSlide";
import { LanguageContent } from "@components/Shared/model/LanguageContent";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Effective Risk Management Strategies for Metal Pricing | Ryerson",
		description:
			"Navigate the volatility of metal prices with Ryerson's expert risk management team. Discover how hedging and tailored pricing solutions can enhance your steel purchasing strategy and mitigate market fluctuations.",
		url: `/metal-solutions/solutions/risk-management`,
		imageUrl:
			"/images/metal-solutions/solutions/risk-management/risk-management-hero-video-thumbnail.jpg",
		company: true,
	},
	fr: {
		title: "Effective Risk Management Strategies for Metal Pricing | Ryerson",
		description:
			"Navigate the volatility of metal prices with Ryerson's expert risk management team. Discover how hedging and tailored pricing solutions can enhance your steel purchasing strategy and mitigate market fluctuations.",
		url: `/metal-solutions/solutions/risk-management`,
		imageUrl:
			"/images/metal-solutions/solutions/risk-management/risk-management-hero-video-thumbnail.jpg",
		company: true,
	},
	es: {
		title: "Effective Risk Management Strategies for Metal Pricing | Ryerson",
		description:
			"Navigate the volatility of metal prices with Ryerson's expert risk management team. Discover how hedging and tailored pricing solutions can enhance your steel purchasing strategy and mitigate market fluctuations.",
		url: `/metal-solutions/solutions/risk-management`,
		imageUrl:
			"/images/metal-solutions/solutions/risk-management/risk-management-hero-video-thumbnail.jpg",
		company: true,
	},
};

export const HeroContent: LanguageContent<GrowHeroContent> = {
	en: {
		title: "Risk Management",
		videoId: "NIWSsdo1sgo",
		subTitle: "Metal prices can be volatile. Your ability to mitigate price risk shouldn't be.",
		description: `Ryerson's in-house risk management team can be an asset to your steel-purchasing strategy. By offering pricing solutions such as hedging, you can potentially mitigate the impact of market volatility on metal costs.`,
		thumbnailUrl:
			"/images/metal-solutions/solutions/risk-management/risk-management-hero-video-thumbnail.jpg",
		buttonLabel: "Contact Us",
		buttonUrl: "/contact-us",
	},
	fr: {
		title: "Gestion du risque",
		videoId: "NIWSsdo1sgo",
		subTitle:
			"Les prix des métaux peuvent être instables. Votre capacité à atténuer le risque de prix ne devrait pas l'être.",
		description: `L'équipe interne de gestion des risques de Ryerson peut constituer un atout pour votre stratégie d'achat d'acier. En proposant des solutions de tarification comme la couverture, vous pouvez potentiellement atténuer l'incidence de l'instabilité du marché sur les coûts des métaux.`,
		thumbnailUrl:
			"/images/metal-solutions/solutions/risk-management/risk-management-hero-video-thumbnail.jpg",
		buttonLabel: "Communiquer Avec Nous",
		buttonUrl: "/contact-us",
	},
	es: {
		title: "Gestión de riesgos",
		videoId: "NIWSsdo1sgo",
		subTitle:
			"Los precios de los metales pueden ser volátiles. Su habilidad para reducir el riesgo no debería serlo.",
		description: `El equipo interno de manejo del riesgo de Ryerson puede ser un activo para su estrategia de compra de acero. Al ofrecer soluciones de precios como cobertura, puede reducir potencialmente el efecto de la volatilidad del mercado en los costos de metales.`,
		thumbnailUrl:
			"/images/metal-solutions/solutions/risk-management/risk-management-hero-video-thumbnail.jpg",
		buttonLabel: "Contáctenos",
		buttonUrl: "/contact-us",
	},
};

export const EquipmentAndProcessContent: LanguageContent<TextImageSlide> = {
	en: {
		title: "Which Metal Pricing Strategy is Right for You? ",
		navLabel: "Which Metal Pricing Strategy is Right for You? ",
		sections: [
			{
				title: "Hedging",
				imageUrl: "/images/metal-solutions/solutions/risk-management/hedging.jpg",
				description:
					"Also known as fixed/firm, this pricing model takes a variable price and makes it fixed. This option can provide flexibility to decide order specifics and mill lead times and typically requires no long-term inventory investment.",
				actionText: "Let's Discuss Your Project",
				actionLink: "/contact-us",
			},
			{
				title: "Index",
				imageUrl: "/images/metal-solutions/solutions/risk-management/index.jpg",
				description:
					"Enter into longer-term agreements to lock-in volume at an agreed upon mechanism tied to an industry index. This offers flexibility in the structure of the adjustment mechanism as well.",
				actionText: "Let's Discuss Your Project",
				actionLink: "/contact-us",
			},
			{
				title: "Open Market & Import",
				imageUrl: "/images/metal-solutions/solutions/risk-management/open-markets.jpg",
				description:
					"Commonly referred to as 'spot buying', the open market model allows you to buy directly from the mill at the current market price for that material. As for import, this weighs world market pricing vs. domestic pricing, and typically carries risks involved with longer lead times, transit complications, and trade regulations.",
				actionText: "Let's Discuss Your Project",
				actionLink: "/contact-us",
			},
		],
	},
	fr: {
		title: "Quelle stratégie de fixation des prix des métaux vous convient le mieux? ",
		navLabel: "Quelle stratégie de fixation des prix des métaux vous convient le mieux? ",
		sections: [
			{
				title: "Couverture",
				imageUrl: "/images/metal-solutions/solutions/risk-management/hedging.jpg",
				description:
					"Également connu sous le nom de prix fixe/ferme, ce modèle de tarification prend un prix variable et le rend fixe. Cette option offre une certaine souplesse pour décider des spécificités des commandes et des délais de fabrication et ne nécessite généralement pas d’investissement à long terme e stocks.",
				actionText: "Discutons de votre projet",
				actionLink: "/contact-us",
			},
			{
				title: "Indice",
				imageUrl: "/images/metal-solutions/solutions/risk-management/index.jpg",
				description:
					"Conclure des accords à plus long terme pour bloquer le volume à un mécanisme convenu lié à un indice de l’industrie. Cette option offre également une certaine souplesse dans la structure du mécanisme d’ajustement.",
				actionText: "Discutons de votre projet",
				actionLink: "/contact-us",
			},
			{
				title: "Marché libre et importation",
				imageUrl: "/images/metal-solutions/solutions/risk-management/open-markets.jpg",
				description:
					"Communément appelé « achat ponctuel », le modèle du marché libre vous permet d’acheter directement auprès de l’usine au prix du marché actuel pour ce matériau. Quant à l’importation, elle tient compte des prix du marché mondial par rapport aux prix nationaux et comporte généralement des risques liés à des délais de livraison plus longs, à des complications liées au transit et à la réglementation commerciale.",
				actionText: "Discutons de votre projet",
				actionLink: "/contact-us",
			},
		],
	},
	es: {
		title: "¿Qué estrategia de valoración de precios de metales es mejor para usted? ",
		navLabel: "¿Qué estrategia de valoración de precios de metales es mejor para usted? ",
		sections: [
			{
				title: "Cobertura",
				imageUrl: "/images/metal-solutions/solutions/risk-management/hedging.jpg",
				description:
					"También conocido como fijo/firme, este modelo de precios toma un precio variable y lo hace fijo. Esta opción puede ofrecer flexibilidad para decidir las especificaciones del pedido y los tiempos de entrega de fabricación, y usualmente no requiere inversión de inventario a largo plazo.",
				actionText: "Hablemos de su proyecto",
				actionLink: "/contact-us",
			},
			{
				title: "Índice",
				imageUrl: "/images/metal-solutions/solutions/risk-management/index.jpg",
				description:
					"Firme acuerdos a largo plazo para fijar en volumen a un mecanismo acordado relacionado con un índice de la industria. También ofrece flexibilidad en la estructura del mecanismo de ajuste.",
				actionText: "Hablemos de su proyecto",
				actionLink: "/contact-us",
			},
			{
				title: "Mercado abierto e importación",
				imageUrl: "/images/metal-solutions/solutions/risk-management/open-markets.jpg",
				description:
					"Llamado usualmente “compra inmediata”, el modelo de mercado abierto le permite comprar directamente del fabricante al precio actual del mercado para ese material. Respecto a la importación, compara el precio en el mercado mundial con el precio doméstico, y usualmente conlleva riesgos involucrados con mayores tiempos de entrega, complicaciones de tránsito y regulaciones comerciales.",
				actionText: "Hablemos de su proyecto",
				actionLink: "/contact-us",
			},
		],
	},
};

export const WhoDoWeHelpContent: LanguageContent<SolutionsWeOfferContent> = {
	en: {
		title1: "Who Do We Help?",
		title2: " ",
		title: "Who Do We Help",
		actionUrl: "/metal-solutions/solutions",
		actionLabel: "Learn More",
		description: `Companies producing world-class products have one critical need: the right metal at the right moment. At Ryerson, we don't take that responsibility lightly. It's why thousands of companies across hundreds of markets turn to us for their metal needs.`,
		buttonLabel: "Lets Talk!",
		buttonUrl: "/contact-us",
		cards: [
			{
				icon: "solution-advanced-processing",
				text: "Advanced \n Processing",
				backgroundUrl: "/images/metal-solutions/AdvancedProcessingStrip.jpg",
				link: "/metal-solutions/solutions/advanced-processing",
			},
			{
				icon: "solution-global-accounts",
				text: "Additive \n Manufacturing",
				backgroundUrl: "/images/metal-solutions/solutions/additive-manufacturing-slice.jpg",
				link: "/metal-solutions/solutions/additive-manufacturing",
			},
			{
				icon: "solution-custom-aluminum-extrusions",
				text: "Custom Aluminum \n Extrusions",
				backgroundUrl: "/images/metal-solutions/CustomAluminumExtrusionsStrip.jpg",
				link: "/metal-solutions/solutions/custom-aluminum-extrusions",
			},
			{
				icon: "solution-carbon-sheet-solutions",
				text: "Services",
				backgroundUrl: "/images/metal-solutions/solutions/services-truck.jpg",
				link: "/metal-solutions/solutions/services",
			},
		],
	},
	fr: {
		title1: "Qui aidons-nous?",
		title2: "",
		title: "Qui aidons-nous",
		actionUrl: "/metal-solutions/solutions",
		actionLabel: "Apprendre encore plus",
		description: `Les entreprises produisant des produits de classe mondiale ont un besoin essentiel : le bon métal au bon moment. Chez Ryerson, nous ne prenons pas cette responsabilité à la légère. C'est pourquoi des milliers d'entreprises sur des centaines de marchés se tournent vers nous pour leurs besoins en métaux.`,
		buttonLabel: "Discutons",
		buttonUrl: "/contact-us",
		cards: [
			{
				icon: "solution-advanced-processing",
				text: "Traitement \n avancé",
				backgroundUrl: "/images/metal-solutions/AdvancedProcessingStrip.jpg",
				link: "/metal-solutions/solutions/advanced-processing",
			},
			{
				icon: "solution-global-accounts",
				text: "Fabrication additive",
				backgroundUrl: "/images/metal-solutions/solutions/additive-manufacturing-slice.jpg",
				link: "/metal-solutions/solutions/additive-manufacturing",
			},
			{
				icon: "solution-custom-aluminum-extrusions",
				text: "Profilés d'aluminium \n personnalisés",
				backgroundUrl: "/images/metal-solutions/CustomAluminumExtrusionsStrip.jpg",
				link: "/metal-solutions/solutions/custom-aluminum-extrusions",
			},
			{
				icon: "solution-carbon-sheet-solutions",
				text: "Services",
				backgroundUrl: "/images/metal-solutions/solutions/services-truck.jpg",
				link: "/metal-solutions/solutions/services",
			},
		],
	},
	es: {
		title1: "¿A quién ayudamos?",
		title2: "",
		title: "¿A quién ayudamos",
		actionUrl: "/metal-solutions/solutions",
		actionLabel: "Aprende más",
		description: `Las empresas que fabrican productos de clase mundial tienen una necesidad fundamental: el metal adecuado en el momento adecuado. En Ryerson, no tomamos esa responsabilidad a la ligera. Es por eso que miles de empresas en cientos de mercados recurren a nosotros para sus necesidades de metales.`,
		buttonLabel: "Hablemos",
		buttonUrl: "/contact-us",
		cards: [
			{
				icon: "solution-advanced-processing",
				text: "Procesamiento \n avanzado",
				backgroundUrl: "/images/metal-solutions/AdvancedProcessingStrip.jpg",
				link: "/metal-solutions/solutions/advanced-processing",
			},
			{
				icon: "solution-global-accounts",
				text: "Fabricación aditiva",
				backgroundUrl: "/images/metal-solutions/solutions/additive-manufacturing-slice.jpg",
				link: "/metal-solutions/solutions/additive-manufacturing",
			},
			{
				icon: "solution-custom-aluminum-extrusions",
				text: "Extrusiones de aluminio \n personalizadas",
				backgroundUrl: "/images/metal-solutions/CustomAluminumExtrusionsStrip.jpg",
				link: "/metal-solutions/solutions/custom-aluminum-extrusions",
			},
			{
				icon: "solution-carbon-sheet-solutions",
				text: "Servicios",
				backgroundUrl: "/images/metal-solutions/solutions/services-truck.jpg",
				link: "/metal-solutions/solutions/services",
			},
		],
	},
};

export const ExploreOtherCapabilitiesSolutionsContent: LanguageContent<ExploreOtherCapabilitiesSolutionsProps> =
	{
		en: {
			title: "Explore other Capabilities and Solutions",
			section: [
				{
					imageUrl: "/images/metal-solutions/explore-images/Fabrication.jpg",
					mainText: "Fabrication",
					subText: `From precision cutting to punching, bending and welding, we have the capabilities to transform metal into what you need.`,
					badgeText: "Capabilities",
					badgeColor: "other",
					linkUrl: "/metal-solutions/capabilities/fabrication/",
				},
				{
					imageUrl:
						"/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
					mainText: "Advanced Processing",
					subText:
						"We have the ability to produce engineered-to-order parts that meet your exact specifications. Our facilities have diverse capabilities, providing quick service and specialized processing.",
					badgeText: "Solution",
					badgeColor: "error",
					linkUrl: "/metal-solutions/solutions/advanced-processing",
				},
			],
			learnMoreText: "Learn more about our Metal Solutions",
			learnMoreUrl: "/metal-solutions",
		},
		fr: {
			title: "Parcourir les autres capacités et solutions",
			section: [
				{
					imageUrl: "/images/metal-solutions/explore-images/Fabrication.jpg",
					mainText: "Fabrication",
					subText: `De la découpe de précision au poinçonnage, au pliage et au soudage, nous avons les capacités de transformer le métal en ce dont vous avez besoin.`,
					badgeText: "Capacité",
					badgeColor: "other",
					linkUrl: "/metal-solutions/capabilities/fabrication/",
				},
				{
					imageUrl:
						"/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
					mainText: "Traitement avancé",
					subText:
						"Nous avons la capacité de produire des pièces conçues sur commande qui répondent exactement à vos spécifications. Nos installations ont des capacités diverses, offrant un service rapide et un traitement spécialisé.",
					badgeText: "Solution",
					badgeColor: "error",
					linkUrl: "/metal-solutions/solutions/advanced-processing/",
				},
			],
			learnMoreText: "En savoir plus sur nos solutions métalliques",
			learnMoreUrl: "/metal-solutions",
		},
		es: {
			title: "Explore otras capacidades y soluciones",
			section: [
				{
					imageUrl: "/images/metal-solutions/explore-images/Fabrication.jpg",
					mainText: "Fabricación",
					subText: `De la découpe de précision au poinçonnage, au pliage et au soudage, nous avons les capacités de transformer le métal en ce dont vous avez besoin.`,
					badgeText: "Capacidad ",
					badgeColor: "other",
					linkUrl: "/metal-solutions/capabilities/fabrication/",
				},
				{
					imageUrl:
						"/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
					mainText: "Procesamiento avanzado",
					subText:
						"Tenemos la habilidad de producir partes diseñadas conforme al pedido que cumplan con sus especificaciones exactas. Nuestras instalaciones tienen diversas capacidades, proporcionando servicio rápido y procesamiento especializado.",
					badgeText: "Solución",
					badgeColor: "error",
					linkUrl: "/metal-solutions/solutions/advanced-processing/",
				},
			],
			learnMoreText: "Obtenga más información sobre nuestras soluciones de metal",
			learnMoreUrl: "/metal-solutions",
		},
	};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [
		HeroContent,
		EquipmentAndProcessContent,
		WhoDoWeHelpContent,
		ExploreOtherCapabilitiesSolutionsContent,
	],
};
