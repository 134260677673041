import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Container } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import { Button } from "@ryerson/frontend.button";
import { CustomizedMetalSolutionsProps } from "../CustomizedMetalSolutions";
import { navigate } from "gatsby";
import { Link } from "@ryerson/frontend.navigation";

const RootContainer = styled.div`
	max-width: 1440px;
	margin-left: auto;
	margin-right: auto;
	min-height: 800px;
	${(props: any) => {
		const { breakPoint, background } = props;
		return css`
			background-color: ${background};

			@media only screen and (max-width: ${breakPoint}) {
				min-height: 650px;
			}
		`;
	}}
`;

const BaseContainer = styled.div`
	position: relative;
	width: 100%;
`;

const ContactContainer = styled.div`
	height: auto;
	width: 100%;
	display: block;
	vertical-align: middle;
	text-align: left;
	margin-top: 30px;
	padding: 20px;
	box-sizing: border-box;
	${(props: any) => {
		const { theme } = props;
		return css`
			font-family: ${theme.typography.fontFamilies.primary};
			background-color: ${theme.colors.primary.lighterGray};
		`;
	}}
`;

const ContactPortrait = styled.div`
	width: 60px;
	height: 60px;
	border-radius: 80px;
	background-size: cover;
	background-position: center center;
	display: inline-block;
	margin-top: 40px;
	margin-bottom: 35px;
	vertical-align: top;
	${(props: any) => {
		return css`
			background-image: url(${props.contact.portrait});
		`;
	}}
`;

const ContactLineInformation = styled.div`
	margin-top: 50px;
	display: inline-block;
	width: calc(100% - 80px);
	vertical-align: top;

	margin-top: 40px;
	margin-bottom: 35px;
`;

const ThingLinkContainer = styled.div`
	margin-top: 30px;
	width: 100%;
	height: auto;
	padding-bottom: 35px;
	box-sizing: border-box;
	display: block;
	${(props: any) => {
		const { theme } = props;
		return css`
			background-color: ${theme.colors.primary.darkGray};
		`;
	}}
`;

const SecondaryContentContainer = styled.div`
	width: 100%;
	display: block;
	padding: 5px 20px;
`;

const Divider = styled.hr`
	width: 100%;
	opacity: 0.1;
	${(props: any) => {
		const { theme } = props;
		return css`
			border-top: 1px solid ${theme.colors.primary.darkGray};
		`;
	}}
`;
const CustomizedMetalSolutionsMobile: React.FC<CustomizedMetalSolutionsProps> = (props) => {
	const { theme } = useTheme();
	const breakPoint = theme.breakpoints.xl;
	const { white, lightGray, darkGray, secondaryBrand, gray } = theme.colors.primary;
	const background = white;

	return (
		<BaseContainer className="base-container" css={{ backgroundColor: background }}>
			<RootContainer {...[background, breakPoint]}>
				<Container hPadding="0">
					<Typography color={darkGray} variant="h4" size="lg">
						{props.mainDescription}
					</Typography>
					<ThingLinkContainer theme={theme}>
						<SecondaryContentContainer>
							<Typography
								variant="h5"
								color={lightGray}
								css={css`
									margin: 25px 20px 35px 20px;
								`}
							>
								{props.thinglink.description}
							</Typography>
						</SecondaryContentContainer>
						<iframe
							style={{ width: "100%", border: "none" }}
							data-original-width={props.thinglink.originalWidth}
							data-original-height={props.thinglink.originalHeight}
							scrolling="no"
							src={"https://www.thinglink.com/card/" + props.thinglink.id}
						></iframe>
					</ThingLinkContainer>
					<ContactContainer theme={theme}>
						<Typography variant="h3" type="secondary">
							{props.contact.heading}
						</Typography>
						<Divider theme={theme} />
						<ContactLineInformation>
							<Typography variant="div" weight="bold" color={secondaryBrand}>
								{props.contact.name}
							</Typography>
							<Typography variant="div" weight="normal" color={gray}>
								{props.contact.title.indexOf("—") !== -1
									? props.contact.title.split("—").map((t) => (
											<>
												{t}
												<br />
											</>
									  ))
									: props.contact.title}
							</Typography>
							<Typography variant="div" color={gray}>
								<Link
									gatsby={false}
									to={`mailto:${props.contact.email}`}
									color={theme.colors.primary.text}
								>
									{props.contact.email}
								</Link>
							</Typography>
						</ContactLineInformation>
						<ContactPortrait {...props} />
						<Button
							onClick={() => {
								navigate("/contact-us#productQuestion");
							}}
							fullwidth={true}
							label={props.contact.button}
						/>
					</ContactContainer>
				</Container>
			</RootContainer>
		</BaseContainer>
	);
};
export default CustomizedMetalSolutionsMobile;
