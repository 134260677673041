import React from "react";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { css } from "@emotion/react";
import { Button } from "@ryerson/frontend.button";
import { Link } from "@ryerson/frontend.navigation";
import { Dropdown, Checkbox, Input } from "@ryerson/frontend.form-controls";
import { Accordion, AccordionTab } from "@ryerson/frontend.expansion";
import { Popover } from "@ryerson/frontend.modal";
import { Badge } from "@ryerson/frontend.notification";
import { Icon } from "@ryerson/frontend.assets";
import { useTheme } from "@ryerson/frontend.theme";
import { useApplication } from "@ryerson/frontend.application";
import { Media } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import Map from "@components/Locations/Map/Map";
import { Modal } from "@ryerson/frontend.modal";
import LocationInput from "./LocationInput";
import { FormatPhoneNumber } from "./Locations";
import { ExpansionBox, Expander } from "@ryerson/frontend.expansion";
import { ColorPicker, SpecialColor } from "./Landing/ColorPicker";
import {
	LanguageFilters,
	ProcessFilters,
	LocationTypeFilters,
	MaterialFilters,
} from "./Landing/Language";
import {
	PopoverIcon,
	ModalClose,
	MenuDivider,
	SearchBox,
	FindALocation,
	Within,
	Apply,
	ApplyButton,
	FilterBadge,
	FilterBadgeIcon,
	ClearFilters,
	ClearFiltersIcon,
	ResultContainer,
	Filters,
	LeftPane,
	UnsavedLocations,
	RightPane,
	LocationsFound,
	LocationsList,
	LocationsListItem,
	LocationListItemImage,
	LocationListItemText,
	FauxButtonLink,
	FauxIcon,
	Spacer,
	MobileFilterBadge,
	MobileOverlayBody,
	MobileOverlayHeaderClose,
	MobileOverlayHeader,
	MobileOverlay,
	ButtonContainer,
	ButtonContain,
	ModalDiv,
} from "./Landing/Styled";
import { HoursLine } from "./Landing/Hours";
import { calculateDistance } from "./Landing/Math";

export type LocationsPageStaticContent = {
	title: string;
	description: string;
	stsDescription?: string;
	addMyLocation: string;
	findALocation: string;
	customLabel: string;
	icon: string;
	color: string;
	save: string;
	addAnotherLocation: string;
	within: string;
	apply: string;
	pickFromList: string;
	useCurrentLocation: string;
	filterBy: string;
	clearFilters: string;
	processing: string;
	locationType: string;
	directions: string;
	edit: string;
	delete: string;
	viewLocationDetails: string;
	milesAway: string;
	suggestions: string;
	found: string;
	metalShape: string;
	cancel: string;
	hours: string;
	add: string;
};

export type LandingProps = {
	staticContent: LocationsPageStaticContent;
	contentfulContent: any;
	stateData: any;
};

//Popover triggers are what the user clicks to show the popover box
type PopoverTriggerContent = {
	title: string;
	expanded: boolean;
};
//The actual trigger requires it's expansion status, and a title
const PopoverTrigger: React.FC<PopoverTriggerContent> = ({ title, expanded }) => {
	const { theme } = useTheme();

	return (
		<Flex alignItems="center">
			<Typography variant="span" color={theme.colors.primary.white} size="sm">
				{title}
			</Typography>
			<PopoverIcon
				style={{
					transform: expanded ? "rotate(0deg)" : "rotate(180deg)",
				}}
			>
				<Icon
					icon="chevron-up"
					size="xs"
					color={theme.colors.primary.white}
					selected={true}
				/>
			</PopoverIcon>
		</Flex>
	);
};

//The menu content within the popovers
type PopoverMenuContent = {
	title: string;
	childItems: string[];
	extraStyle?: string;
	filtersSelected: any[];
	changeFilters: (filter: string, checked: boolean) => void;
};
//The popover menus themselves have a title child items, and sometimes extra style passed.
const PopoverMenu: React.FC<PopoverMenuContent> = ({
	title,
	childItems,
	extraStyle = "",
	filtersSelected,
	changeFilters,
}) => {
	const { theme } = useTheme();
	const {
		localization: { language },
	} = useApplication();

	return (
		<Flex
			direction="column"
			justifyContent="flex-start"
			style={css`
				max-height: 350px;
				text-align: left;
				${extraStyle}
			`}
		>
			<Typography variant="span" size="md" color={theme.colors.primary.header} weight="bold">
				{LanguageFilters[language][title]}
			</Typography>
			<MenuDivider color={theme.colors.primary.darkGray} />
			{childItems.map((item: string, index: number) => {
				return (
					<Typography
						key={index}
						variant="div"
						size="sm"
						color={theme.colors.primary.header}
						css={css`
							margin-bottom: 2px;
						`}
					>
						<Checkbox
							language={language}
							value={item}
							label={LanguageFilters[language][item]}
							size="sm"
							checked={filtersSelected.indexOf(title + ": " + item) !== -1}
							onChange={(val, checked) => {
								changeFilters(title + ": " + item, checked);
							}}
						/>
					</Typography>
				);
			})}
		</Flex>
	);
};

type MenuProps = {
	filtersSelected: any[];
	changeFilters: (filter: string, checked: boolean) => void;
	title: string;
};
//The location type popover menu
const LocationTypeMenu: React.FC<MenuProps> = ({ filtersSelected, changeFilters, title }) => {
	const { theme } = useTheme();
	const {
		localization: { language },
	} = useApplication();

	//Is the location type filter popover open?
	const [locationTypeIsExpanded, setLocationTypeIsExpanded] = React.useState(false);

	return (
		<Popover
			popoverType="menu"
			offset={[0, 12]}
			onOpen={() => {
				setLocationTypeIsExpanded(true);
			}}
			onClose={() => {
				setLocationTypeIsExpanded(false);
			}}
			trigger={<PopoverTrigger title={title} expanded={locationTypeIsExpanded} />}
		>
			<>
				<Flex
					css={css`
						width: 200px;
						background-color: ${theme.colors.primary.white};
						padding: 20px;
						box-sizing: border-box;
						box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07),
							0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
							0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725);
						${language === "en" ? "height: 170px;" : "height: 185px;"}
					`}
					justifyContent="space-between"
				>
					<FlexItem
						css={css`
							width: 100%;
							max-width: 160px;
						`}
					>
						<PopoverMenu
							title="Location Type"
							childItems={LocationTypeFilters}
							filtersSelected={filtersSelected}
							changeFilters={changeFilters}
						/>
					</FlexItem>
				</Flex>
			</>
		</Popover>
	);
};
//The processing filter popover menu
const ProcessingMenu: React.FC<MenuProps> = ({ filtersSelected, changeFilters, title }) => {
	const { theme } = useTheme();

	//Is the processing filter popover open?
	const [processingIsExpanded, setProcessingIsExpanded] = React.useState(false);

	return (
		<Popover
			popoverType="menu"
			offset={[0, 12]}
			onOpen={() => {
				setProcessingIsExpanded(true);
			}}
			onClose={() => {
				setProcessingIsExpanded(false);
			}}
			trigger={<PopoverTrigger title={title} expanded={processingIsExpanded} />}
		>
			<>
				<Flex
					css={css`
						width: 600px;
						height: 270px;
						background-color: ${theme.colors.primary.white};
						padding: 20px;
						box-sizing: border-box;
						box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07),
							0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
							0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725);
					`}
					justifyContent="space-between"
				>
					<FlexItem
						css={css`
							width: 100%;
							max-width: 160px;
						`}
					>
						<PopoverMenu
							title={ProcessFilters[0].title}
							childItems={ProcessFilters[0].filters}
							filtersSelected={filtersSelected}
							changeFilters={changeFilters}
						/>
					</FlexItem>
					<FlexItem
						css={css`
							width: 100%;
							max-width: 160px;
						`}
					>
						<PopoverMenu
							title={ProcessFilters[1].title}
							childItems={ProcessFilters[1].filters}
							filtersSelected={filtersSelected}
							changeFilters={changeFilters}
						/>
					</FlexItem>
					<FlexItem
						css={css`
							width: 100%;
							max-width: 160px;
						`}
					>
						<PopoverMenu
							title={ProcessFilters[2].title}
							childItems={ProcessFilters[2].filters}
							filtersSelected={filtersSelected}
							changeFilters={changeFilters}
						/>
					</FlexItem>
				</Flex>
			</>
		</Popover>
	);
};
//The metals and shape popover menu
const MetalAndShapeMenu: React.FC<MenuProps> = ({ filtersSelected, changeFilters, title }) => {
	const { theme } = useTheme();
	const {
		localization: { language },
	} = useApplication();

	//Is the metals and shape filter popover open?
	const [metalsAndShapeExpanded, setMetalsAndShapeExpanded] = React.useState(false);

	return (
		<Popover
			popoverType="menu"
			offset={[0, 12]}
			onOpen={() => {
				setMetalsAndShapeExpanded(true);
			}}
			onClose={() => {
				setMetalsAndShapeExpanded(false);
			}}
			trigger={<PopoverTrigger title={title} expanded={metalsAndShapeExpanded} />}
		>
			<>
				<Flex
					css={css`
						width: 880px;
						// height: 465px; //CHANGE THIS HEIGHT PER LANGUAGE, eng 465px, fr 510px, es 510px
						background-color: ${theme.colors.primary.white};
						padding: 20px 20px 0px 20px;
						box-sizing: border-box;
						box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07),
							0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
							0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725);
						${language === "en" ? "height: 465px;" : "height: 510px;"}
					`}
					justifyContent="space-between"
				>
					<FlexItem
						css={css`
							width: 25%;
							max-width: 180px;
						`}
					>
						<PopoverMenu
							title={MaterialFilters[0].title}
							childItems={MaterialFilters[0].filters}
							filtersSelected={filtersSelected}
							changeFilters={changeFilters}
						/>
					</FlexItem>
					<FlexItem
						css={css`
							width: 25%;
							max-width: 180px;
						`}
					>
						<PopoverMenu
							title={MaterialFilters[1].title}
							childItems={MaterialFilters[1].filters}
							filtersSelected={filtersSelected}
							changeFilters={changeFilters}
						/>
					</FlexItem>
					<FlexItem
						css={css`
							width: 25%;
							max-width: 180px;
						`}
					>
						<PopoverMenu
							title={MaterialFilters[2].title}
							childItems={MaterialFilters[2].filters}
							filtersSelected={filtersSelected}
							changeFilters={changeFilters}
						/>
					</FlexItem>
					<FlexItem
						css={css`
							width: 25%;
							max-width: 180px;
						`}
					>
						<PopoverMenu
							title={MaterialFilters[3].title}
							childItems={MaterialFilters[3].filters}
							filtersSelected={filtersSelected}
							changeFilters={changeFilters}
						/>
						<PopoverMenu
							title={MaterialFilters[4].title}
							childItems={MaterialFilters[4].filters}
							extraStyle="margin-top: 30px;"
							filtersSelected={filtersSelected}
							changeFilters={changeFilters}
						/>
					</FlexItem>
				</Flex>
			</>
		</Popover>
	);
};

const Landing: React.FC<LandingProps> = ({ staticContent, contentfulContent, stateData }) => {
	/************************************************************************
    ----------PAGE SETUP----------------------------------------------------- 
    ************************************************************************/
	const { theme } = useTheme();
	const {
		localization: { language },
	} = useApplication();
	//We save the current body tag style for when we slide a drawer over for mobile filters
	const [bodyStyle, setBodyStyle] = React.useState<any>("");
	//Should the mobile drawer of filter options be showing?
	const [showMobileFilters, setShowMobileFilters] = React.useState<boolean>(false);
	//Within distance in miles, defaults 25
	const [within, setWithin] = React.useState(25);
	//Flag to show/hide the alert modal of whether geolocation services are enabled/disabled or blocked
	const [geolocationShow, setGeolocationShow] = React.useState(false);
	//What message to diplsay in the geolocation services alert modal
	const [geolocationMessage, setGeolocationMessage] = React.useState("");
	//Has the user entered a location or used their current location?
	const [myLocation, setMyLocation] = React.useState(false);
	//The searched location or use my location formated data.
	const [myLocationDetails, setMyLocationDetails] = React.useState<any>(null);
	//The reference passed to the location input for imperative handling
	const locationInputReference = React.createRef<any>();
	//Which marker has been selected
	const [selectMarker, setSelectMarker] = React.useState<any>(null);
	//If user uses multiple locations modal, they are saved here
	const [savedLocations, setSavedLocations] = React.useState<any[]>([]);
	//Curently searched on lat/lng when apply distance within filter
	const [searchOnLocation, setSearchOnLocation] = React.useState({
		lat: 0,
		lng: 0,
	});
	/************************************************************************
    ----------CONTENTFUL & MAP CONTROLLERS----------------------------------- 
    ************************************************************************/
	//Raw contentful data of locations source of truth variable
	const locations: any[] = [];
	contentfulContent.forEach((nodes: any) => {
		if (nodes.node.node_locale.split("-").shift() === language) {
			locations.push(nodes.node);
		}
	});
	//The current locations that should exist on the map
	const [mapLocations, setMapLocations] = React.useState<any[]>([]);
	//The current locations that the boundary limit should show
	const [locationsShowing, setLocationsShowing] = React.useState<any[]>([]);
	//When the boundary changes from zooming or from moving around the map, we need to remap locations
	const boundaryFilter = (top: any, left: any, bottom: any, right: any) => {
		let boundaryLocations: any[] = [];
		locations.forEach((location: any) => {
			if (
				location.location.lat > bottom &&
				location.location.lat < top &&
				location.location.lon > left &&
				location.location.lon < right
			) {
				boundaryLocations.push(location);
			}
		});
		setLocationsShowing(boundaryLocations);
	};
	//Any time we change the list of base locations of interest, we should refresh the map locations
	React.useEffect(() => {
		refreshMapLocations(null, null);
	}, [locationsShowing]);
	//If the user asks to use their location, or enteres a location in search, apply rerenders the map
	const applySearch = () => {
		if (myLocationDetails.location.lat && myLocationDetails.location.lng) {
			//User entered a new address or clicked the use current location buttons
			setMyLocation(true);
			setSearchOnLocation({
				lat: myLocationDetails.location.lat,
				lng: myLocationDetails.location.lng,
			});
			refreshMapLocations(
				{
					lat: myLocationDetails.location.lat,
					lng: myLocationDetails.location.lng,
				},
				null
			);
		}
	};
	//When user enters a location, or uses their current location, setup for the apply button
	const addMyLocation = (position: any, title: any | null) => {
		let oldMapLocations = [...mapLocations];
		oldMapLocations.push();
		let newLoc = { ...searchOnLocation };
		newLoc.lat = position.coords.latitude;
		newLoc.lng = position.coords.longitude;
		if (locationInputReference && locationInputReference.current) {
			locationInputReference.current.setValue(
				String(position.coords.latitude) + ", " + String(position.coords.longitude)
			);
		}
		setMyLocationDetails({
			location: {
				lat: position.coords.latitude,
				lng: position.coords.longitude,
			},
			details: {},
			title: title ? title : "My Location",
			type: "myLocation",
			id: "myLocationId",
			customColor: "darkYellow",
			distance: 0,
			iconLabel: {
				text: "1",
				color: theme.colors.primary.white,
			},
			customMarker: "/images/locations/markers/darkYellow.png",
		});
	};
	//When filters have been changed, or "my location" has changed, map locations must refresh
	//To account for distance-to, and whether they should exist based on the current filtering
	const refreshMapLocations = (searchOn: any | null, newSavedLocations: any | null) => {
		let newMapLocations: any[] = [...mapLocations];
		newMapLocations.length = 0;
		if (myLocationDetails) newMapLocations.push(myLocationDetails);
		let x = myLocationDetails ? 2 : 1;
		/*
            Complicated:
            If the user is trying to filter down to a specific geographic location
            using the search field, or their own location with a "within" range
            OR the user is limiting locations to multiple locations using a "within"
            range, then we want the map to show all locations on it, regardless of 
            whether they are in view or not. However, if the user is simply zooming
            and dragging the map around without using a "within" filter, then we
            want to only show locations that are within the boundary of the map.
        */
		let useBoundary = true;
		if (newSavedLocations !== null) {
			if (newSavedLocations.length > 0) useBoundary = false;
		} else if (savedLocations.length > 0) useBoundary = false;
		if (
			searchOn &&
			(searchOn.lat < 0 || searchOn.lat > 0) &&
			(searchOn.lng < 0 || searchOn.lng > 0)
		) {
			useBoundary = false;
		} else if (
			(searchOnLocation.lat < 0 || searchOnLocation.lat > 0) &&
			(searchOnLocation.lng < 0 || searchOnLocation.lng > 0)
		) {
			useBoundary = false;
		}
		/*
            At this point, we should know whether to serve a list of locations based on
            the viewable boundary, or if we should just show all locations "within"
            the user's desired rules, and we can pick which list to parse through 
            for our map locations.
        */
		const locationList = useBoundary ? locationsShowing : locations;
		let priorityCountry = "United States";
		if (typeof window !== "undefined") {
			let currentUrl = window.location.href;
			if (currentUrl.includes("united-states")) {
				priorityCountry = "United States";
			} else if (currentUrl.includes("canada")) {
				priorityCountry = "Canada";
			} else if (currentUrl.includes("mexico")) {
				priorityCountry = "Mexico";
			}
		}
		locationList
			.sort((a: any, b: any) => {
				if (a.country === priorityCountry && b.country !== priorityCountry) {
					return -1; // a comes before b
				}
				if (a.country !== priorityCountry && b.country === priorityCountry) {
					return 1; // b comes before a
				}
				if (
					a.facilityType === "Ryerson Facility" &&
					b.facilityType !== "Ryerson Facility"
				) {
					return -1;
				} else {
					return 1;
				}
			})
			.forEach((location: any) => {
				let isInResult = true;
				if (filtersSelected.length > 0) {
					for (let i = 0; i < filtersSelected.length; i++) {
						let currentFilter = filtersSelected[i];
						if (
							currentFilter &&
							currentFilter.length > 0 &&
							currentFilter.indexOf("Location Type") === -1
						) {
							if (
								location.filters &&
								location.filters.length > 0 &&
								location.filters.indexOf(currentFilter) === -1
							) {
								isInResult = false;
								break;
							}
						} else {
							if (
								currentFilter &&
								"Location Type: " + location.facilityType !== currentFilter
							) {
								isInResult = false;
								break;
							}
						}
					}
				}
				let distance = "0";
				if (isInResult) {
					let parseByMany = false;
					if (newSavedLocations !== null) {
						if (newSavedLocations.length > 0) parseByMany = true;
					} else if (savedLocations.length > 0) parseByMany = true;
					if (parseByMany) {
						let isWithinOne = false;
						let tmpSavedLocations;
						if (newSavedLocations) {
							//we just dismissed the modal with new saved locations
							tmpSavedLocations = newSavedLocations;
						} else {
							//we are refreshing but already have saved locations
							tmpSavedLocations = savedLocations;
						}
						tmpSavedLocations.forEach((savedLoc: any) => {
							let tmpDistance = calculateDistance(
								location.location.lat,
								location.location.lon,
								savedLoc.lat,
								savedLoc.lng
							);
							if (Number(tmpDistance) <= savedLoc.within) isWithinOne = true;
						});
						isInResult = isWithinOne;
					} else {
						if (
							searchOn &&
							(searchOn.lat < 0 || searchOn.lat > 0) &&
							(searchOn.lng < 0 || searchOn.lng > 0)
						) {
							setSearchOnLocation(searchOn);
							distance = calculateDistance(
								location.location.lat,
								location.location.lon,
								searchOn.lat,
								searchOn.lng
							);
							if (Number(distance) > within) isInResult = false;
						} else if (
							(searchOnLocation.lat < 0 || searchOnLocation.lat > 0) &&
							(searchOnLocation.lng < 0 || searchOnLocation.lng > 0)
						) {
							distance = calculateDistance(
								location.location.lat,
								location.location.lon,
								searchOnLocation.lat,
								searchOnLocation.lng
							);
							if (Number(distance) > within) isInResult = false;
						}
					}
				}
				let link =
					"/locations/" + String(location.country).toLowerCase().replace(" ", "-") + "/";
				if (location.country && location.country !== "Mexico") {
					link +=
						String(location.state).toLowerCase().replace(" ", "-") +
						"/" +
						location.slug;
				} else {
					link += location.slug;
				}
				if (isInResult) {
					newMapLocations.push({
						location: {
							lat: location.location.lat,
							lng: location.location.lon,
						},
						details: location,
						link: link,
						title: location.locationName,
						type: "contentful",
						id: location.contentful_id,
						customColor: "darkBlue",
						customMarker:
							location.facilityType === "Ryerson Facility"
								? "/images/locations/markers/darkBlue.png"
								: "/images/locations/markers/gray.png",
						distance: distance,
						iconLabel: {
							text: String(x),
							color: theme.colors.primary.white,
						},
					});
					x++;
				}
			});
		if ((newSavedLocations && newSavedLocations.length > 0) || savedLocations.length > 0) {
			let tmpSavedLocations;
			if (newSavedLocations) {
				//we just dismissed the modal with new saved locations
				tmpSavedLocations = newSavedLocations;
			} else {
				//we are refreshing but already have saved locations
				tmpSavedLocations = savedLocations;
			}
			tmpSavedLocations.forEach((savedLoc: any, index: number) => {
				newMapLocations.push({
					location: {
						lat: savedLoc.lat,
						lng: savedLoc.lng,
					},
					details: {},
					title: savedLoc.label,
					type: "savedLocation",
					id: index,
					customColor: savedLoc.color,
					customMarker: "/images/locations/markers/" + savedLoc.color + ".png",
					distance: 0,
					iconLabel: {
						text: String(x),
						color: theme.colors.primary.white,
					},
				});
				x++;
			});
		}
		setMapLocations(newMapLocations);
	};
	/*************************************************************
    ---------FILTERS----------------------------------------------
    *************************************************************/
	//Array of filters that are actively filtering the locations
	const [filtersSelected, setFiltersSelected] = React.useState<any[]>([]);
	//Array of filters that are setup to filter, but apply has not been hit yet ( mobile only )
	const [tempFiltersSelected, setTempFiltersSelected] = React.useState<any[]>([]);
	//Whenever filters are changed, refresh the map to refine results to filters
	React.useEffect(() => {
		refreshMapLocations(null, null);
	}, [filtersSelected]);
	//When a filter is checked/unchecked remap the selectred filters, triggering a new map refresh
	const changeFilters = (value: any, checked: boolean) => {
		const oldFilters: any[] = [...filtersSelected];
		if (checked === true) {
			if (oldFilters.indexOf(value) !== -1) {
				//do nothing
			} else {
				oldFilters.push(value);
			}
		} else {
			if (oldFilters.indexOf(value) !== -1) {
				oldFilters.splice(oldFilters.indexOf(value), 1);
			} else {
				//do nothing
			}
		}
		setFiltersSelected(oldFilters);
	};
	//On mobile, we do not filter until the user clicks apply, so we hold their work in a temporary variable
	const changeTempFilters = (value: any, checked: boolean) => {
		const oldFilters: any[] = [...tempFiltersSelected];
		if (checked === true) {
			if (oldFilters.indexOf(value) !== -1) {
				//do nothing
			} else {
				oldFilters.push(value);
			}
		} else {
			if (oldFilters.indexOf(value) !== -1) {
				oldFilters.splice(oldFilters.indexOf(value), 1);
			} else {
				//do nothing
			}
		}
		setTempFiltersSelected(oldFilters);
	};
	//When the user clicks the clear filters button, empty them and refresh the map
	const clearAllFilters = () => {
		let oldFilters = [...filtersSelected];
		oldFilters.length = 0;
		setFiltersSelected(oldFilters);
	};
	//To keep filter language multilingual, we use this function to translate
	const getFilterItemLanguage = (item: any, mobile: boolean) => {
		if (item.indexOf(":") !== -1) {
			let split = item.split(":");
			return (
				<>
					<Typography variant="span" type="primary" size={mobile ? "md" : "sm"}>
						{LanguageFilters[language][split[0].trim()]}
						{": "}
					</Typography>
					<Typography
						variant="span"
						type="primary"
						color={theme.colors.primary.header}
						size={mobile ? "md" : "sm"}
					>
						{LanguageFilters[language][split[1].trim()]}
					</Typography>
				</>
			);
		} else {
			return <></>;
		}
	};

	const [addMyLocationOpen, setAddMyLocationOpen] = React.useState<boolean>(false);
	const addMyLocationInputRef = React.createRef<any>();
	const [unsavedLocation, setUnsavedLocation] = React.useState<any>({
		title: "",
		lat: null,
		lng: null,
		label: "",
		color: "darkYellow",
		within: 25,
	});
	const [unsavedLocations, setUnsavedLocations] = React.useState<any[]>([]);
	const updateUnsavedLocation = (key: any, value: any) => {
		setUnsavedLocation((prevState: any) => ({
			...prevState,
			[key]: value,
		}));
	};
	const addUnsavedLocation = () => {
		const newUnsavedLocations = [...unsavedLocations];
		newUnsavedLocations.push(unsavedLocation);
		setUnsavedLocations(newUnsavedLocations);
		setUnsavedLocation((prevState: any) => ({
			...prevState,
			lat: null,
			lng: null,
			title: "",
			label: "",
			color: "darkYellow",
			within: 25,
		}));
		if (addMyLocationInputRef && addMyLocationInputRef.current)
			addMyLocationInputRef.current.setValue("");
	};
	const updateUnsavedLocations = function (index: number, key: any, value: any) {
		setUnsavedLocations((state) => {
			return state.map((item, i) => {
				return i === index ? { ...item, [key]: value } : item;
			});
		});
	};
	const removeUnsavedLocation = (index: number) => {
		const newUnsavedLocations = [...unsavedLocations];
		newUnsavedLocations.splice(index, 1);
		setUnsavedLocations(newUnsavedLocations);
	};

	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ContentSection type="tertiary" vPadding="60px">
					<Typography
						variant="h1"
						type="tertiary"
						css={css`
							letter-spacing: -2px;
							margin-bottom: 50px;
						`}
					>
						{staticContent.title}
					</Typography>
					<Typography
						variant="div"
						color={theme.colors.tertiary.header}
						size="md"
						css={css`
							display: inline-block;
							vertical-align: middle;
							width: calc(90% - 200px);
						`}
					>
						{process.env.GATSBY_COMPANY === "southernToolSteel"
							? staticContent.stsDescription
							: staticContent.description}
					</Typography>
					<Typography
						variant="div"
						css={css`
							display: inline-block;
							vertical-align: middle;
							width: calc(200px + 10%);
							text-align: right;
						`}
					>
						<Button
							label={staticContent.addMyLocation}
							type="secondary"
							size="sm"
							paddingType="relaxed"
							css={css`
								display: inline-block;
							`}
							onClick={() => {
								setAddMyLocationOpen(true);
							}}
						/>
					</Typography>
					<SearchBox
						css={css`
							background-color: ${theme.colors.primary.darkGray};
						`}
					>
						<FindALocation>
							<Typography
								variant="div"
								css={css`
									display: block;
									width: 100%;
									margin-bottom: 9px;
								`}
								color={theme.colors.primary.white}
								size="md"
							>
								{staticContent.findALocation}
							</Typography>
							<LocationInput
								impRef={locationInputReference}
								onLocationSelected={(lat, lng, title) => {
									addMyLocation(
										{
											coords: {
												latitude: lat,
												longitude: lng,
											},
										},
										title
									);
								}}
							/>
						</FindALocation>
						<Within>
							<Typography
								variant="div"
								css={css`
									display: block;
									width: 100%;
									margin-bottom: 9px;
								`}
								color={theme.colors.primary.white}
								size="md"
							>
								{staticContent.within}
							</Typography>
							<Dropdown
								shape="rounded"
								value={within}
								options={[
									{ display: "25 miles", value: "25" },
									{ display: "50 miles", value: "50" },
									{ display: "100 miles", value: "100" },
									{ display: "250 miles", value: "250" },
									{ display: "500 miles", value: "500" },
									{ display: "1000 miles", value: "1000" },
								]}
								onChange={(val) => {
									setWithin(Number(val));
								}}
								language={language}
							/>
						</Within>
						<Apply>
							<ApplyButton
								css={css`
									border: 1px solid ${theme.colors.primary.lightGray};
									cursor: pointer;
								`}
								onClick={() => {
									applySearch();
								}}
							>
								<Typography
									variant="span"
									size="sm"
									color={theme.colors.primary.white}
								>
									{staticContent.apply}
								</Typography>
							</ApplyButton>
						</Apply>
						<Spacer />
						<Spacer />
						<Flex justifyContent="space-between" direction="row">
							<FlexItem>
								{/*
								<FauxButtonLink
									css={css`
										margin-right: 20px;
									`}
								>
									<FauxIcon
										css={css`
											background-color: ${theme.colors.primary.primaryBrand};
										`}
									>
										<Icon
											icon="bars"
											color={theme.colors.primary.white}
											size="xs"
										/>
									</FauxIcon>
									<Typography
										variant="span"
										color={theme.colors.primary.white}
										size="sm"
										css={css`
											vertical-align: middle;
											line-height: 16px;
										`}
									>
										{staticContent.pickFromList}
									</Typography>
								</FauxButtonLink>
                                */}
								<FauxButtonLink
									onClick={() => {
										if (navigator.geolocation) {
											navigator.geolocation.getCurrentPosition(
												(position: any) => {
													addMyLocation(position, null);
												},
												(error) => {
													setGeolocationMessage(
														"Use My Location feature unavailable without allowing geolocation permission."
													);
													setGeolocationShow(true);
												}
											);
										} else {
											setGeolocationMessage(
												"Use My Location feature unavailable in browsers without geolocation features."
											);
											setGeolocationShow(true);
										}
									}}
								>
									<FauxIcon
										css={css`
											background-color: ${theme.colors.primary.primaryBrand};
										`}
									>
										<Icon
											icon="location"
											color={theme.colors.primary.white}
											size="xs"
										/>
									</FauxIcon>
									<Typography
										variant="span"
										color={theme.colors.primary.white}
										size="sm"
										css={css`
											vertical-align: middle;
											line-height: 16px;
										`}
									>
										{staticContent.useCurrentLocation}
									</Typography>
								</FauxButtonLink>
							</FlexItem>
							<FlexItem>
								<Typography
									variant="span"
									size="sm"
									color={theme.colors.primary.lightGray}
									css={css`
										margin-right: 20px;
										display: inline-block;
										line-height: 16px;
										vertical-align: middle;
									`}
								>
									{staticContent.filterBy}
									{":"}
								</Typography>
								<Typography
									variant="span"
									size="sm"
									color={theme.colors.primary.lightGray}
									css={css`
										margin-right: 20px;
										display: inline-block;
										line-height: 16px;
										vertical-align: middle;
									`}
								>
									<MetalAndShapeMenu
										filtersSelected={filtersSelected}
										changeFilters={changeFilters}
										title={staticContent.metalShape}
									/>
								</Typography>
								<Typography
									variant="span"
									size="sm"
									color={theme.colors.primary.lightGray}
									css={css`
										margin-right: 20px;
										display: inline-block;
										line-height: 16px;
										vertical-align: middle;
									`}
								>
									<ProcessingMenu
										filtersSelected={filtersSelected}
										changeFilters={changeFilters}
										title={staticContent.processing}
									/>
								</Typography>
								<Typography
									variant="span"
									size="sm"
									color={theme.colors.primary.lightGray}
									css={css`
										display: inline-block;
										line-height: 16px;
										vertical-align: middle;
									`}
								>
									<LocationTypeMenu
										filtersSelected={filtersSelected}
										changeFilters={changeFilters}
										title={staticContent.locationType}
									/>
								</Typography>
							</FlexItem>
						</Flex>
					</SearchBox>
					<ResultContainer>
						<Filters
							css={css`
								background-color: ${theme.colors.secondary.background};
							`}
						>
							{filtersSelected.length > 0 ? (
								<>
									{filtersSelected.map((filterItem: any, index: number) => {
										return (
											<FilterBadge
												key={index}
												css={css`
													background-color: ${theme.colors.primary
														.background};
												`}
											>
												<Typography variant="span" type="primary" size="sm">
													<Typography
														variant="span"
														size="sm"
														color={theme.colors.primary.header}
													>
														{getFilterItemLanguage(filterItem, false)}
													</Typography>
													<FilterBadgeIcon>
														<Icon
															icon="close"
															size="xs"
															onClick={() => {
																changeFilters(filterItem, false);
															}}
														/>
													</FilterBadgeIcon>
												</Typography>
											</FilterBadge>
										);
									})}
									<ClearFilters onClick={clearAllFilters}>
										<ClearFiltersIcon
											css={css`
												border: 1px solid ${theme.colors.primary.gray};
											`}
										>
											<Icon
												size="xs"
												color={theme.colors.primary.darkerGray}
												icon="close"
											/>
										</ClearFiltersIcon>
										<Typography variant="span" size="sm" type="secondary">
											{staticContent.clearFilters}
										</Typography>
									</ClearFilters>
									<Spacer
										css={css`
											height: 10px;
										`}
									/>
								</>
							) : (
								<Spacer
									css={css`
										height: 20px;
									`}
								/>
							)}
						</Filters>
						<LeftPane
							css={css`
								background-color: ${theme.colors.primary.background};
							`}
						>
							<LocationsFound
								css={css`
									border-bottom: 1px solid ${theme.colors.primary.lighterGray};
								`}
							>
								<Typography variant="span" size="sm" weight="bold">
									{(myLocation ? mapLocations.length - 1 : mapLocations.length) +
										" " +
										staticContent.title +
										" "}
								</Typography>
								<Typography variant="span" size="sm">
									{staticContent.found}
								</Typography>
							</LocationsFound>
							<LocationsList>
								{mapLocations.map((location: any, index: number) => {
									//PlaceholderLocationImage.jpg
									return (
										<React.Fragment key={index}>
											<LocationsListItem
												key={index}
												onClick={() => {
													setSelectMarker(location);
												}}
												css={
													selectMarker !== null &&
													selectMarker.title &&
													selectMarker.type &&
													selectMarker.title === location.title &&
													selectMarker.type === location.type
														? css`
																background-color: ${theme.colors
																	.primary.primaryBrand};
														  `
														: css`
																background-color: ${theme.colors
																	.primary.background};
														  `
												}
											>
												<LocationListItemImage>
													<img
														src={
															location.details &&
															location.details.locationPicture &&
															location.details.locationPicture !==
																null
																? location.details.locationPicture
																		.file.url
																: location.type === "contentful"
																? "/images/locations/PlaceholderLocationImage.jpg"
																: "/images/locations/LocationPlaceholder.jpg"
														}
													/>
												</LocationListItemImage>

												<LocationListItemText>
													<Flex
														justifyContent="space-between"
														direction="column"
														css={css`
															height: 100%;
														`}
													>
														<FlexItem>
															<Typography
																variant="h4"
																color={theme.colors.primary.link}
																css={css`
																font-size: 20px;
																margin-bottom: 14px;
                                                                ${
																	selectMarker !== null &&
																	selectMarker.title &&
																	selectMarker.title ===
																		location.title
																		? "color: " +
																		  theme.colors.primary
																				.white +
																		  ";"
																		: ""
																}}
															`}
															>
																{String(index + 1) + ". "}
																{location.details &&
																location.details.locationName
																	? location.details.locationName
																	: location.title}
															</Typography>
															<Flex
																direction="row"
																justifyContent="space-between"
															>
																<FlexItem>
																	<Typography
																		variant="span"
																		size="xs"
																		color={
																			theme.colors.primary
																				.white
																		}
																		css={css`
																			display: inline-block;
																			width: auto;
																			padding: 4px 11px;
																			height: 24px;
																			border-radius: 24px;
																			background-color: ${location.type ===
																			"contentful"
																				? location.details
																						.facilityType ===
																				  "Ryerson Facility"
																					? theme.colors
																							.primary
																							.secondaryBrand
																					: theme.colors
																							.primary
																							.gray
																				: location.type ===
																				  "myLocation"
																				? theme.colors
																						.secondary
																						.darkOrange
																				: theme.colors
																						.secondary[
																						location.customColor as SpecialColor
																				  ]};
																		`}
																	>
																		{location.type ===
																		"contentful"
																			? location.details.facilityType.replace(
																					"Facility",
																					""
																			  )
																			: location.type ===
																			  "myLocation"
																			? "My Location"
																			: "Custom Location"}
																	</Typography>
																</FlexItem>

																{location.type ===
																"savedLocation" ? (
																	<FlexItem>
																		<FauxButtonLink
																			onClick={() => {
																				setAddMyLocationOpen(
																					true
																				);
																			}}
																			css={css`
																				margin-right: 15px;
																			`}
																		>
																			<Typography
																				variant="span"
																				size="sm"
																				color={
																					selectMarker !==
																						null &&
																					selectMarker.title &&
																					selectMarker.type &&
																					selectMarker.title ===
																						location.title &&
																					selectMarker.type ===
																						location.type
																						? theme
																								.colors
																								.primary
																								.white
																						: theme
																								.colors
																								.primary
																								.link
																				}
																			>
																				<Icon
																					icon="edit"
																					css={css`
																						vertical-align: top;
																						margin-right: 5px;
																					`}
																					color={
																						selectMarker !==
																							null &&
																						selectMarker.title &&
																						selectMarker.type &&
																						selectMarker.title ===
																							location.title &&
																						selectMarker.type ===
																							location.type
																							? theme
																									.colors
																									.primary
																									.white
																							: theme
																									.colors
																									.primary
																									.link
																					}
																					size="sm"
																				/>
																				{"Edit"}
																			</Typography>
																		</FauxButtonLink>
																		<FauxButtonLink
																			onClick={() => {
																				const newUnsavedLocations =
																					[
																						...unsavedLocations,
																					];
																				newUnsavedLocations.splice(
																					location.id,
																					1
																				);
																				setUnsavedLocations(
																					newUnsavedLocations
																				);
																				setSavedLocations(
																					newUnsavedLocations
																				);
																				refreshMapLocations(
																					null,
																					newUnsavedLocations
																				);
																			}}
																			css={css`
																				margin-right: 10px;
																			`}
																		>
																			<Typography
																				variant="span"
																				size="sm"
																				color={
																					selectMarker !==
																						null &&
																					selectMarker.title &&
																					selectMarker.type &&
																					selectMarker.title ===
																						location.title &&
																					selectMarker.type ===
																						location.type
																						? theme
																								.colors
																								.primary
																								.white
																						: theme
																								.colors
																								.primary
																								.link
																				}
																			>
																				<Icon
																					icon="trash"
																					css={css`
																						vertical-align: top;
																						margin-right: 5px;
																					`}
																					color={
																						selectMarker !==
																							null &&
																						selectMarker.title &&
																						selectMarker.type &&
																						selectMarker.title ===
																							location.title &&
																						selectMarker.type ===
																							location.type
																							? theme
																									.colors
																									.primary
																									.white
																							: theme
																									.colors
																									.primary
																									.link
																					}
																					size="sm"
																				/>
																				{"Delete"}
																			</Typography>
																		</FauxButtonLink>
																	</FlexItem>
																) : (
																	<></>
																)}
															</Flex>
														</FlexItem>
														{location.type === "contentful" ? (
															<FlexItem>
																<Typography
																	css={css`
																margin-bottom: 10px;
                                                                ${
																	selectMarker !== null &&
																	selectMarker.title &&
																	selectMarker.title ===
																		location.title
																		? "color: " +
																		  theme.colors.primary
																				.white +
																		  ";"
																		: ""
																}}
															`}
																	variant="div"
																	size="sm"
																	type="primary"
																>
																	{location.details
																		.streetAddress +
																		" " +
																		location.details.city +
																		" " +
																		location.details.state +
																		" " +
																		location.details.zipCode}
																</Typography>
																<Typography
																	css={css`
																margin-bottom: 10px;
                                                                ${
																	selectMarker !== null &&
																	selectMarker.title &&
																	selectMarker.title ===
																		location.title
																		? "color: " +
																		  theme.colors.primary
																				.white +
																		  ";"
																		: ""
																}}
															`}
																	variant="div"
																	size="sm"
																	type="primary"
																>
																	{FormatPhoneNumber(
																		location.details.phoneNumber
																	)}
																</Typography>
																<Typography
																	variant="div"
																	size="sm"
																	css={css`
                                                                    display:block;
                                                                    width:100%;
                                                                    position:relative;
                                                                    height:20px;
                                                                    ${
																		selectMarker !== null &&
																		selectMarker.title &&
																		selectMarker.title ===
																			location.title
																			? "color: " +
																			  theme.colors.primary
																					.white +
																			  ";"
																			: ""
																	}}`}
																>
																	<Link
																		to={location.link}
																		color={
																			selectMarker !== null &&
																			selectMarker.title &&
																			selectMarker.title ===
																				location.title
																				? theme.colors
																						.primary
																						.white
																				: theme.colors
																						.primary
																						.primaryBrand
																		}
																	>
																		{
																			staticContent.viewLocationDetails
																		}
																	</Link>
																	<Typography
																		variant="span"
																		size="sm"
																		color={
																			theme.colors.primary
																				.gray
																		}
																		css={css`
																			position: absolute;
																			right: 0;
																			top: 0;
																		`}
																	>
																		{location.distance > 0
																			? location.distance +
																			  " miles away"
																			: ""}
																	</Typography>
																</Typography>
															</FlexItem>
														) : (
															<></>
														)}
													</Flex>
												</LocationListItemText>
											</LocationsListItem>
											{index === mapLocations.length - 1 ? (
												<></>
											) : (
												<MenuDivider
													color={theme.colors.primary.darkGray}
													css={css`
														margin-top: 0px;
														margin-bottom: -1px;
													`}
												/>
											)}
										</React.Fragment>
									);
								})}
							</LocationsList>
						</LeftPane>
						<RightPane>
							<div
								css={css`
									width: 100%;
									height: 100%;
									display: block;
									.gm-style-iw-t .gm-style-iw-c {
										padding-right: 0px !important;
										padding-bottom: 0px !important;
										padding-left: 0px !important;
										overflow: visible !important;
										padding-top: 0px !important;
									}
									.gm-style-iw-d {
										padding-right: 0px !important;
										padding-bottom: 0px !important;
										padding-left: 0px !important;
										padding-top: 0px !important;
										overflow-x: hidden !important;
										overflow-y: auto !important;
									}
									button.gm-ui-hover-effect {
										display: block !important;
										border: none !important;
										margin: 0px !important;
										padding: 0px !important;
										position: absolute !important;
										cursor: pointer !important;
										user-select: none !important;
										width: 24px !important;
										height: 24px !important;
										border-radius: 24px !important;
										background-color: ${theme.colors.primary
											.darkerGray} !important;
										top: -12px !important;
										right: -12px !important;
										background: none !important;
										text-align: center !important;
										font-family: sans-serif !important;
										background-image: url(/images/locations/mapCloseButton.png) !important;
										background-size: 100% 100% !important;
										background-position: center center !important;
										img {
											display: none !important;
										}
									}
								`}
							>
								<Map
									googleKey={process.env.GATSBY_GOOGLE_MAPS_KEY ?? ""}
									mapData={mapLocations ?? []}
									customMarkerScale={[30, 30]}
									markerToSelect={selectMarker !== null ? selectMarker : {}}
									zoomLevel={stateData && stateData.zoom ? stateData.zoom : 6}
									handleDrag={boundaryFilter}
									center={stateData && stateData.center ? stateData.center : null}
									handleMarkerSelected={(mapData) => {
										setSelectMarker(mapData);
									}}
									infoWindowContent={(props: any) => {
										return props.type === "myLocation" ? (
											<Typography
												variant="div"
												css={css`
													padding: 20px;
													max-width: 340px;
												`}
												type="primary"
												size="md"
											>
												{props.title}
											</Typography>
										) : props.type === "savedLocation" ? (
											<Typography
												variant="div"
												css={css`
													padding: 20px;
													max-width: 340px;
												`}
												type="primary"
												size="md"
											>
												{props.title}
											</Typography>
										) : (
											<>
												{props.details.locationPicture !== null &&
												props.details.locationPicture.file.url ? (
													<Typography
														css={css`
															display: block;
															width: 340px;
															position: relative;
															height: auto;
															background-color: ${theme.colors.primary
																.background};
															img {
																width: 100%;
																height: auto;
															}
														`}
														variant="div"
													>
														<img
															src={
																props.details.locationPicture.file
																	.url
															}
														/>
														<Typography
															variant="span"
															color={theme.colors.primary.white}
															size="xs"
															css={css`
																display: block;
																width: auto;
																height: 24px;
																white-space: nowrap;
																position: absolute;
																padding: 4px 11px;
																left: 20px;
																border-radius: 22px;
																top: 20px;
																background-color: ${props.details
																	.facilityType ===
																"Ryerson Facility"
																	? theme.colors.primary
																			.secondaryBrand
																	: props.details.facilityType ===
																	  "Ryerson Family of Companies Facility"
																	? theme.colors.primary.lightGray
																	: theme.colors.secondary
																			.darkOrange};
															`}
														>
															{props.details.facilityType.replace(
																"Facility",
																""
															)}
														</Typography>
													</Typography>
												) : (
													<></>
												)}
												<Typography
													variant="div"
													css={css`
														display: block;
														padding-left: 20px;
														box-sizing: border-box;
														width: 340px;
														height: auto;
														padding-right: 20px;
														padding-top: 15px;
														padding-bottom: 20px;
													`}
												>
													<Typography
														css={css`
															margin-bottom: 11px;
															letter-spacing: -1px;
															font-size: 20px;
														`}
														variant="h4"
														size="lg"
														color={theme.colors.primary.link}
													>
														{props.details.locationName}
													</Typography>
													<Typography
														variant="div"
														size="sm"
														type="primary"
													>
														{props.details.streetAddress +
															" " +
															props.details.city +
															" " +
															props.details.state +
															" " +
															props.details.zipCode}
													</Typography>
													<Typography
														css={css`
															margin-bottom: 10px;
														`}
														variant="div"
														size="sm"
														type="primary"
													>
														{FormatPhoneNumber(
															props.details.phoneNumber
														)}
													</Typography>
													<Link to={props.link}>
														{staticContent.viewLocationDetails}
													</Link>
													<MenuDivider
														color={theme.colors.primary.darkGray}
														css={css`
															margin-top: 19px;
															margin-bottom: 22px;
														`}
													/>
													<Typography
														variant="div"
														size="sm"
														weight="bold"
														css={css`
															margin-bottom: 12px;
														`}
													>
														{staticContent.hours}
													</Typography>
													{props.details.locationHours ? (
														<>
															<HoursLine
																open={
																	props.details.locationHours
																		.sundayOpen
																}
																close={
																	props.details.locationHours
																		.sundayClose
																}
																label="Sun:"
															/>
															<HoursLine
																open={
																	props.details.locationHours
																		.mondayOpen
																}
																close={
																	props.details.locationHours
																		.mondayClose
																}
																label="Mon:"
															/>
															<HoursLine
																open={
																	props.details.locationHours
																		.tuesdayOpen
																}
																close={
																	props.details.locationHours
																		.tuesdayClose
																}
																label="Tue:"
															/>
															<HoursLine
																open={
																	props.details.locationHours
																		.wednesdayOpen
																}
																close={
																	props.details.locationHours
																		.wednesdayClose
																}
																label="Wed:"
															/>
															<HoursLine
																open={
																	props.details.locationHours
																		.thursdayOpen
																}
																close={
																	props.details.locationHours
																		.thursdayClose
																}
																label="Thu:"
															/>
															<HoursLine
																open={
																	props.details.locationHours
																		.fridayOpen
																}
																close={
																	props.details.locationHours
																		.fridayClose
																}
																label="Fri:"
															/>
															<HoursLine
																open={
																	props.details.locationHours
																		.saturdayOpen
																}
																close={
																	props.details.locationHours
																		.saturdayClose
																}
																label="Sun:"
															/>
														</>
													) : (
														<></>
													)}

													<MenuDivider
														color={theme.colors.primary.darkGray}
														css={css`
															margin-top: 19px;
															margin-bottom: 20px;
														`}
													/>

													<Link
														gatsby={false}
														to={
															"//maps.google.com/?ll=" +
															props.details.location.lat +
															"," +
															props.details.location.lon
														}
													>
														{staticContent.directions}
													</Link>
												</Typography>
											</>
										);
									}}
								></Map>
							</div>
						</RightPane>
					</ResultContainer>
				</ContentSection>
				<Modal
					bodyType="primary"
					type="primary"
					closable={false}
					buttonArea={
						<ButtonContainer>
							<ButtonContain>
								<Button
									label={staticContent.cancel}
									paddingType="relaxed"
									size="sm"
									type="secondary"
									shape="rounded"
									variant="outline"
									onClick={() => {
										setAddMyLocationOpen(false);
									}}
								/>
							</ButtonContain>
							<ButtonContain>
								<Button
									paddingType="relaxed"
									size="sm"
									shape="rounded"
									type="secondary"
									label={staticContent.save}
									onClick={() => {
										setSavedLocations(unsavedLocations);
										setAddMyLocationOpen(false);
										refreshMapLocations(null, unsavedLocations);
									}}
								/>
							</ButtonContain>
						</ButtonContainer>
					}
					isOpen={addMyLocationOpen}
					onClose={() => {
						setAddMyLocationOpen(false);
					}}
				>
					<ModalDiv>
						<ContentSection vPadding="0px" hPadding="0px" type="primary">
							<Typography
								variant="h4"
								css={css`
									font-size: 20px;
									margin-bottom: 20px;
								`}
							>
								{staticContent.addMyLocation}
							</Typography>
							<FindALocation
								css={css`
									width: calc(45% - 180px);
								`}
							>
								<Typography
									variant="div"
									css={css`
										display: block;
										width: 100%;
										margin-bottom: 9px;
									`}
									type="primary"
									size="md"
								>
									{staticContent.findALocation}
								</Typography>
								<LocationInput
									impRef={addMyLocationInputRef}
									onLocationSelected={(lat, lng, title) => {
										updateUnsavedLocation("lat", lat);
										updateUnsavedLocation("lng", lng);
										updateUnsavedLocation("title", title);
									}}
								/>
							</FindALocation>
							<Within
								css={css`
									width: 150px;
								`}
							>
								<Typography
									variant="div"
									css={css`
										display: block;
										width: 100%;
										margin-bottom: 9px;
									`}
									type="primary"
									size="md"
								>
									{staticContent.within}
								</Typography>
								<Dropdown
									shape="rounded"
									value={unsavedLocation.within}
									options={[
										{ display: "25 miles", value: "25" },
										{ display: "50 miles", value: "50" },
										{ display: "100 miles", value: "100" },
										{ display: "250 miles", value: "250" },
										{ display: "500 miles", value: "500" },
										{ display: "1000 miles", value: "1000" },
									]}
									onChange={(val) => {
										updateUnsavedLocation("within", Number(val));
									}}
									language={language}
								/>
							</Within>
							<Within
								css={css`
									width: calc(55% - 180px);
								`}
							>
								<Typography
									variant="div"
									css={css`
										display: block;
										width: 100%;
										margin-bottom: 9px;
									`}
									type="primary"
									size="md"
								>
									{staticContent.customLabel}
								</Typography>
								<Input
									language={language}
									inputType="text"
									shape="rounded"
									value={unsavedLocation.label}
									onChange={(e) => {
										updateUnsavedLocation("label", e.target.value);
									}}
								/>
							</Within>
							<Within
								css={css`
									width: 80px;
								`}
							>
								<Typography
									variant="div"
									css={css`
										display: block;
										width: 100%;
										margin-bottom: 9px;
									`}
									type="primary"
									size="md"
								>
									{staticContent.color}
								</Typography>
								<ColorPicker
									onChange={(color) => {
										updateUnsavedLocation("color", color);
									}}
									value={unsavedLocation.color}
								/>
							</Within>
							<Within
								css={css`
									width: 90px;
									padding-top: 30px;
								`}
							>
								<Button
									type="secondary"
									onClick={() => {
										addUnsavedLocation();
									}}
									fullwidth={true}
									shape="rounded"
									size="sm"
									label={staticContent.add}
								/>
							</Within>
							<FauxButtonLink
								css={css`
									display: block;
									width: 100%;
									margin-top: 24px;
								`}
								onClick={() => {
									if (navigator.geolocation) {
										navigator.geolocation.getCurrentPosition(
											(position: any) => {
												if (
													addMyLocationInputRef &&
													addMyLocationInputRef.current
												) {
													addMyLocationInputRef.current.setValue(
														String(position.coords.latitude) +
															", " +
															String(position.coords.longitude)
													);
												}
												updateUnsavedLocation(
													"lat",
													position.coords.latitude
												);
												updateUnsavedLocation(
													"lng",
													position.coords.longitude
												);
												updateUnsavedLocation("title", "My Location");
											},
											(error) => {
												setGeolocationMessage(
													"Use My Location feature unavailable without allowing geolocation permission."
												);
												setGeolocationShow(true);
											}
										);
									} else {
										setGeolocationMessage(
											"Use My Location feature unavailable in browsers without geolocation features."
										);
										setGeolocationShow(true);
									}
								}}
							>
								<FauxIcon
									css={css`
										background-color: ${theme.colors.primary.primaryBrand};
									`}
								>
									<Icon
										icon="location"
										color={theme.colors.primary.white}
										size="xs"
									/>
								</FauxIcon>
								<Typography
									variant="span"
									color={theme.colors.primary.header}
									weight="bold"
									size="sm"
									css={css`
										vertical-align: middle;
										line-height: 16px;
									`}
								>
									{staticContent.useCurrentLocation}
								</Typography>
							</FauxButtonLink>
							<UnsavedLocations>
								{unsavedLocations.map((location: any, index: number) => {
									return (
										<ResultContainer
											css={css`
												margin-bottom: 10px;
											`}
											key={index}
										>
											<FindALocation
												css={css`
													width: calc(45% - 180px);
												`}
											>
												<Typography
													variant="div"
													css={css`
														display: block;
														width: 100%;
														line-height: 40px !important;
													`}
													type="primary"
													size="md"
													weight="bold"
													color={theme.colors.primary.header}
												>
													{location.title}
												</Typography>
											</FindALocation>
											<Within
												css={css`
													width: 150px;
												`}
											>
												<Dropdown
													shape="rounded"
													value={location.within}
													options={[
														{ display: "25 miles", value: "25" },
														{ display: "50 miles", value: "50" },
														{ display: "100 miles", value: "100" },
														{ display: "250 miles", value: "250" },
														{ display: "500 miles", value: "500" },
														{ display: "1000 miles", value: "1000" },
													]}
													onChange={(val) => {
														updateUnsavedLocations(
															index,
															"within",
															Number(val)
														);
													}}
													language={language}
												/>
											</Within>
											<Within
												css={css`
													width: calc(55% - 180px);
												`}
											>
												<Input
													language={language}
													inputType="text"
													shape="rounded"
													value={location.label}
													onChange={(e) => {
														let newLabel = e.target.value;
														updateUnsavedLocations(
															index,
															"label",
															newLabel
														);
													}}
												/>
											</Within>
											<Within
												css={css`
													width: 80px;
												`}
											>
												<ColorPicker
													onChange={(color) => {
														updateUnsavedLocations(
															index,
															"color",
															color
														);
													}}
													value={location.color}
												/>
											</Within>
											<Within
												css={css`
													width: 90px;
													text-align: center;
													line-height: 40px;
												`}
											>
												<FauxIcon
													onClick={() => {
														removeUnsavedLocation(index);
													}}
													css={css`
														background-color: ${theme.colors.primary
															.gray};
														margin-right: 0px;
													`}
												>
													<Icon
														icon="close"
														size="xs"
														color={theme.colors.primary.white}
													/>
												</FauxIcon>
											</Within>
										</ResultContainer>
									);
								})}
							</UnsavedLocations>
						</ContentSection>
						<ModalClose
							css={css`
								background-color: ${theme.colors.primary.darkGray};
							`}
							onClick={() => {
								setAddMyLocationOpen(false);
							}}
						>
							<Icon icon="close" color={theme.colors.primary.white} size="sm" />
						</ModalClose>
					</ModalDiv>
				</Modal>
			</Media>
			<Media lessThan="lg">
				<ContentSection type="tertiary" vPadding="25px">
					<Typography
						variant="h1"
						type="tertiary"
						css={css`
							letter-spacing: -2px;
							margin-bottom: 35px;
							display: inline-block;
							width: 50%;
							vertical-align: middle;
						`}
					>
						{staticContent.title}
					</Typography>
					<Typography
						variant="div"
						css={css`
							display: inline-block;
							vertical-align: middle;
							width: 50%;
							text-align: right;
							margin-bottom: 35px;
						`}
					>
						<Button
							label={staticContent.addMyLocation}
							type="secondary"
							size="xs"
							paddingType="strict"
							shape="rounded"
							css={css`
								display: inline-block;
							`}
							onClick={() => {
								setAddMyLocationOpen(true);
								const body = document?.querySelector("body");
								setBodyStyle(body?.getAttribute("style") ?? "");
								body && body.setAttribute("style", "overflow: hidden;");
							}}
						/>
					</Typography>
					<MenuDivider
						color={theme.colors.primary.white}
						css={css`
							margin-top: 30px;
							margin-bottom: 30px;
						`}
					/>
					<Typography
						variant="div"
						color={theme.colors.tertiary.header}
						size="md"
						css={css`
							margin-bottom: 10px;
						`}
					>
						{staticContent.description}
					</Typography>
				</ContentSection>
				<ContentSection vPadding="0px" type="primary" hPadding="0px">
					<SearchBox
						css={css`
							background-color: ${theme.colors.primary.darkGray};
							padding: 20px 20px 28px 20px;
							margin-top: 0px;
							box-sizing: border-box;
							border-radius: 0px 0px 0px 0px;
						`}
					>
						<FindALocation
							css={css`
								width: calc(100% - 120px);
							`}
						>
							<Typography
								variant="div"
								css={css`
									display: block;
									width: 100%;
									margin-bottom: 9px;
								`}
								color={theme.colors.primary.white}
								size="md"
							>
								{staticContent.findALocation}
							</Typography>
							<LocationInput
								impRef={locationInputReference}
								onLocationSelected={(lat, lng, title) => {
									addMyLocation(
										{
											coords: {
												latitude: lat,
												longitude: lng,
											},
										},
										title
									);
								}}
							/>
						</FindALocation>
						<Within
							css={css`
								width: 110px;
							`}
						>
							<Typography
								variant="div"
								css={css`
									display: block;
									width: 100%;
									margin-bottom: 9px;
								`}
								color={theme.colors.primary.white}
								size="md"
							>
								{staticContent.within}
							</Typography>
							<Dropdown
								shape="rounded"
								value={within}
								options={[
									{ display: "25 miles", value: "25" },
									{ display: "50 miles", value: "50" },
									{ display: "100 miles", value: "100" },
									{ display: "250 miles", value: "250" },
									{ display: "500 miles", value: "500" },
									{ display: "1000 miles", value: "1000" },
								]}
								onChange={(val) => {
									setWithin(Number(val));
								}}
								language={language}
							/>
						</Within>
						<Apply
							css={css`
								display: block;
								width: 100%;
								margin-left: 0px;
							`}
						>
							<ApplyButton
								css={css`
									border: 1px solid ${theme.colors.primary.lightGray};
									height: 30px;
									margin-top: 20px;
									line-height: 28px;
									border-radius: 30px;
									cursor: pointer;
								`}
								onClick={() => {
									applySearch();
								}}
							>
								<Typography
									variant="span"
									size="md"
									color={theme.colors.primary.white}
								>
									{staticContent.apply}
								</Typography>
							</ApplyButton>
						</Apply>
						<Spacer />
						<Spacer />
						{/*
						<FauxButtonLink
							css={css`
								margin-right: 0px;
								display: block;
								width: 100%;
								margin-bottom: 16px;
							`}
						>
							<FauxIcon
								css={css`
									background-color: ${theme.colors.primary.primaryBrand};
								`}
							>
								<Icon icon="bars" color={theme.colors.primary.white} size="xs" />
							</FauxIcon>
							<Typography
								variant="span"
								color={theme.colors.primary.white}
								size="sm"
								css={css`
									vertical-align: middle;
									line-height: 16px;
								`}
							>
								{staticContent.pickFromList}
							</Typography>
						</FauxButtonLink>
                        */}
						<FauxButtonLink
							css={css`
								display: block;
								width: 100%;
							`}
							onClick={() => {
								if (navigator.geolocation) {
									navigator.geolocation.getCurrentPosition(
										(position: any) => {
											addMyLocation(position, null);
										},
										(error) => {
											setGeolocationMessage(
												"Use My Location feature unavailable without allowing geolocation permission."
											);
											setGeolocationShow(true);
										}
									);
								} else {
									setGeolocationMessage(
										"Use My Location feature unavailable in browsers without geolocation features."
									);
									setGeolocationShow(true);
								}
							}}
						>
							<FauxIcon
								css={css`
									background-color: ${theme.colors.primary.primaryBrand};
								`}
							>
								<Icon
									icon="location"
									color={theme.colors.primary.white}
									size="xs"
								/>
							</FauxIcon>
							<Typography
								variant="span"
								color={theme.colors.primary.white}
								size="sm"
								css={css`
									vertical-align: middle;
									line-height: 16px;
								`}
							>
								{staticContent.useCurrentLocation}
							</Typography>
						</FauxButtonLink>
						<MenuDivider
							color={theme.colors.primary.white}
							css={css`
								margin-top: 22px;
								margin-bottom: 25px;
							`}
						/>
						<Typography
							variant="div"
							css={css`
								display: inline-flex;
								width: 24px;
								height: 24px;
								border-radius: 24px;
								background-color: ${theme.colors.primary.gray};
								text-align: center;
								margin-right: 13px;
								vertical-align: middle;
							`}
						>
							<Badge
								badgeContent={String(filtersSelected.length)}
								badgeType="notification"
								color={theme.colors.primary.primaryBrand}
							>
								<Icon
									color={theme.colors.primary.white}
									size="md"
									circled
									icon="filter"
									onClick={() => {
										setShowMobileFilters(true);
										const body = document?.querySelector("body");
										setBodyStyle(body?.getAttribute("style") ?? "");
										body && body.setAttribute("style", "overflow: hidden;");
									}}
								/>
							</Badge>
						</Typography>
						<Typography
							variant="span"
							size="sm"
							color={theme.colors.primary.white}
							css={css`
								display: inline-block;
								line-height: 16px;
								vertical-align: middle;
							`}
						>
							{staticContent.filterBy}
						</Typography>
					</SearchBox>

					<RightPane
						css={css`
							display: block;
							width: 100%;
							height: 500px;
						`}
					>
						<div
							css={css`
								width: 100%;
								height: 100%;
								display: block;
								.gm-style-iw-t .gm-style-iw-c {
									padding-right: 0px !important;
									padding-bottom: 0px !important;
									padding-left: 0px !important;
									overflow: visible !important;
									padding-top: 0px !important;
								}
								.gm-style-iw-d {
									padding-right: 0px !important;
									padding-bottom: 0px !important;
									padding-left: 0px !important;
									padding-top: 0px !important;
									overflow-x: hidden !important;
									overflow-y: auto !important;
								}
								button.gm-ui-hover-effect {
									display: block !important;
									border: none !important;
									margin: 0px !important;
									padding: 0px !important;
									position: absolute !important;
									cursor: pointer !important;
									user-select: none !important;
									width: 24px !important;
									height: 24px !important;
									border-radius: 24px !important;
									background-color: ${theme.colors.primary.darkerGray} !important;
									top: -12px !important;
									right: -12px !important;
									background: none !important;
									text-align: center !important;
									font-family: sans-serif !important;
									background-image: url(/images/locations/mapCloseButton.png) !important;
									background-size: 100% 100% !important;
									background-position: center center !important;
									img {
										display: none !important;
									}
								}
							`}
						>
							<Map
								googleKey={process.env.GATSBY_GOOGLE_MAPS_KEY ?? ""}
								mapData={mapLocations ?? []}
								zoomLevel={stateData && stateData.zoom ? stateData.zoom : 6}
								handleDrag={boundaryFilter}
								center={stateData && stateData.center ? stateData.center : null}
								customMarkerScale={[30, 30]}
								markerToSelect={selectMarker !== null ? selectMarker : {}}
								handleMarkerSelected={(mapData) => {
									setSelectMarker(mapData);
								}}
								infoWindowContent={(props: any) => {
									return props.type === "myLocation" ? (
										<Typography
											variant="div"
											type="primary"
											css={css`
												max-width: 100%;
												padding: 15px;
												box-sizing: border-box;
											`}
											size="md"
										>
											{props.title}
										</Typography>
									) : props.type === "savedLocation" ? (
										<Typography
											variant="div"
											css={css`
												padding: 20px;
												max-width: 340px;
											`}
											type="primary"
											size="md"
										>
											{props.title}
										</Typography>
									) : (
										<>
											{props.details.locationPicture !== null &&
											props.details.locationPicture.file.url ? (
												<Typography
													css={css`
														display: block;
														width: 340px;
														position: relative;
														height: auto;
														background-color: ${theme.colors.primary
															.background};
														img {
															width: 100%;
															height: auto;
														}
													`}
													variant="div"
												>
													<img
														src={props.details.locationPicture.file.url}
													/>
													<Typography
														variant="span"
														color={theme.colors.primary.white}
														size="xs"
														css={css`
															display: block;
															width: auto;
															height: 24px;
															white-space: nowrap;
															position: absolute;
															padding: 4px 11px;
															left: 20px;
															border-radius: 22px;
															top: 20px;
															background-color: ${props.details
																.facilityType === "Ryerson Facility"
																? theme.colors.primary
																		.secondaryBrand
																: props.details.facilityType ===
																  "Ryerson Family of Companies Facility"
																? theme.colors.primary.lightGray
																: theme.colors.secondary
																		.darkOrange};
														`}
													>
														{props.details.facilityType.replace(
															"Facility",
															""
														)}
													</Typography>
												</Typography>
											) : (
												<></>
											)}
											<Typography
												variant="div"
												css={css`
													display: block;
													padding-left: 20px;
													box-sizing: border-box;
													width: 340px;
													height: auto;
													padding-right: 20px;
													padding-top: 15px;
													padding-bottom: 20px;
												`}
											>
												<Typography
													css={css`
														margin-bottom: 11px;
														letter-spacing: -1px;
														font-size: 20px;
													`}
													variant="h4"
													size="lg"
													color={theme.colors.primary.link}
												>
													{props.details.locationName}
												</Typography>
												<Typography variant="div" size="sm" type="primary">
													{props.details.streetAddress +
														" " +
														props.details.city +
														" " +
														props.details.state +
														" " +
														props.details.zipCode}
												</Typography>
												<Typography
													css={css`
														margin-bottom: 10px;
													`}
													variant="div"
													size="sm"
													type="primary"
												>
													{FormatPhoneNumber(props.details.phoneNumber)}
												</Typography>
												<Link to={props.link}>
													{staticContent.viewLocationDetails}
												</Link>
												<MenuDivider
													color={theme.colors.primary.darkGray}
													css={css`
														margin-top: 19px;
														margin-bottom: 22px;
													`}
												/>
												<Typography
													variant="div"
													size="sm"
													weight="bold"
													css={css`
														margin-bottom: 12px;
													`}
												>
													{staticContent.hours}
												</Typography>
												<HoursLine
													open={props.details.locationHours.sundayOpen}
													close={props.details.locationHours.sundayClose}
													label="Sun:"
												/>
												<HoursLine
													open={props.details.locationHours.mondayOpen}
													close={props.details.locationHours.mondayClose}
													label="Mon:"
												/>
												<HoursLine
													open={props.details.locationHours.tuesdayOpen}
													close={props.details.locationHours.tuesdayClose}
													label="Tue:"
												/>
												<HoursLine
													open={props.details.locationHours.wednesdayOpen}
													close={
														props.details.locationHours.wednesdayClose
													}
													label="Wed:"
												/>
												<HoursLine
													open={props.details.locationHours.thursdayOpen}
													close={
														props.details.locationHours.thursdayClose
													}
													label="Thu:"
												/>
												<HoursLine
													open={props.details.locationHours.fridayOpen}
													close={props.details.locationHours.fridayClose}
													label="Fri:"
												/>
												<HoursLine
													open={props.details.locationHours.saturdayOpen}
													close={
														props.details.locationHours.saturdayClose
													}
													label="Sun:"
												/>
												<MenuDivider
													color={theme.colors.primary.darkGray}
													css={css`
														margin-top: 19px;
														margin-bottom: 20px;
													`}
												/>

												<Link
													gatsby={false}
													to={
														"//maps.google.com/?ll=" +
														props.details.location.lat +
														"," +
														props.details.location.lon
													}
												>
													{staticContent.directions}
												</Link>
											</Typography>
										</>
									);
								}}
							></Map>
						</div>
					</RightPane>
					<LeftPane
						css={css`
							background-color: ${theme.colors.primary.background};
							display: block;
							width: 100%;
							height: auto;
							padding: 25px 20px 30px 20px;
						`}
					>
						<LocationsFound
							css={css`
								height: 20px;
								padding: 0px;
								margin-bottom: 25px;
							`}
						>
							<Typography variant="span" size="md" weight="bold">
								{(myLocation ? mapLocations.length - 1 : mapLocations.length) +
									" " +
									staticContent.title +
									" "}
							</Typography>
							<Typography variant="span" size="md">
								{staticContent.found}
							</Typography>
						</LocationsFound>
						<LocationsList
							css={css`
								height: auto;
							`}
						>
							{mapLocations.map((location: any, index: number) => {
								//PlaceholderLocationImage.jpg
								return location.type === "contentful" ||
									location.type === "addedLocation" ? (
									<LocationsListItem
										key={index}
										onClick={() => {
											setSelectMarker(location);
										}}
										css={css`
											background-color: ${theme.colors.secondary.background};
											padding: 0px;
											margin-bottom: 40px;
											height: auto;
										`}
									>
										<LocationListItemImage
											css={css`
												display: block;
												width: 100%;
												height: 180px;
												position: relative;
												margin-right: 0px;
												background-image: url(${location.details
													.locationPicture !== null
													? location.details.locationPicture.file.url
													: "/images/locations/PlaceholderLocationImage.jpg"});
												background-position: center center;
												background-size: cover;
												background-repeat: no-repeat;
											`}
										>
											<Typography
												variant="h4"
												color={theme.colors.primary.white}
												css={css`
													font-size: 20px !important;
													letter-spacing: -1px;
													display: block;
													width: 100%;
													height: auto;
													padding: 15px 20px;
													box-sizing: border-box;
												`}
											>
												{String(index + 1) + ". "}
												{location.details.locationName}
											</Typography>
											<Typography
												variant="span"
												size="xs"
												color={theme.colors.primary.white}
												css={css`
													display: inline-block;
													width: auto;
													padding: 4px 11px;
													height: 24px;
													position: absolute;
													bottom: 20px;
													left: 20px;
													border-radius: 24px;
													background-color: ${location.type ===
													"contentful"
														? location.details.facilityType ===
														  "Ryerson Facility"
															? theme.colors.primary.secondaryBrand
															: theme.colors.primary.gray
														: location.type === "myLocation"
														? theme.colors.secondary.darkOrange
														: theme.colors.secondary.darkGreen};
												`}
											>
												{location.type === "contentful"
													? location.details.facilityType.replace(
															"Facility",
															""
													  )
													: location.type === "myLocation"
													? "My Location"
													: "Something Else"}
											</Typography>
										</LocationListItemImage>
										<LocationListItemText
											css={css`
												display: block;
												width: 100%;
												padding: 25px 20px 20px 20px;
												height: auto;
											`}
										>
											<Typography
												css={css`
													margin-bottom: 12px;
												`}
												variant="div"
												size="md"
												color={theme.colors.primary.header}
											>
												{location.details.streetAddress +
													" " +
													location.details.city +
													" " +
													location.details.state +
													" " +
													location.details.zipCode}
											</Typography>
											{location.distance > 0 ? (
												<Typography
													variant="div"
													size="md"
													color={theme.colors.primary.gray}
													css={css`
														margin: 0px;
													`}
												>
													{location.distance + " miles away"}
												</Typography>
											) : (
												<></>
											)}
											<Typography
												css={css`
													margin-bottom: 24px;
													margin-top: 22px;
												`}
												variant="div"
												size="md"
												type="primary"
											>
												<Link
													gatsby={false}
													to={"tel:" + location.details.phoneNumber}
												>
													<Icon
														color={theme.colors.primary.primaryBrand}
														icon="phone-outline"
														size="xs"
														css={css`
															margin-right: 10px;
															-webkit-transform: scaleX(-1);
															transform: scaleX(-1);
														`}
													/>
													{FormatPhoneNumber(
														location.details.phoneNumber
													)}
												</Link>
											</Typography>
											<MenuDivider
												color={theme.colors.primary.darkGray}
												css={css`
													margin-top: 0px;
													margin-bottom: -1px;
												`}
											/>
											<ExpansionBox
												header={
													<Typography
														type="secondary"
														variant="div"
														size="md"
														weight="bold"
														color={theme.colors.primary.primaryBrand}
													>
														Learn More
													</Typography>
												}
												defaultOpen={index === 0 ? true : false}
												headerType="secondary"
												bodyType="primary"
												clickableHeader={true}
											>
												<Expander>
													<Typography
														variant="div"
														css={css`
															padding: 17px 20px 25px 20px;
															display: block;
															width: 100%;
															box-sizing: border-box;
															height: auto;
														`}
														size="md"
													>
														<Typography
															variant="div"
															size="sm"
															weight="bold"
															css={css``}
														>
															{staticContent.hours}
														</Typography>
														<MenuDivider
															color={theme.colors.primary.darkGray}
															css={css`
																margin-top: 16px;
																margin-bottom: 15px;
															`}
														/>
														{location.details.locationHours ? (
															<>
																<HoursLine
																	open={
																		location.details
																			.locationHours
																			.sundayOpen
																	}
																	close={
																		location.details
																			.locationHours
																			.sundayClose
																	}
																	label="Sun:"
																/>
																<HoursLine
																	open={
																		location.details
																			.locationHours
																			.mondayOpen
																	}
																	close={
																		location.details
																			.locationHours
																			.mondayClose
																	}
																	label="Mon:"
																/>
																<HoursLine
																	open={
																		location.details
																			.locationHours
																			.tuesdayOpen
																	}
																	close={
																		location.details
																			.locationHours
																			.tuesdayClose
																	}
																	label="Tue:"
																/>
																<HoursLine
																	open={
																		location.details
																			.locationHours
																			.wednesdayOpen
																	}
																	close={
																		location.details
																			.locationHours
																			.wednesdayClose
																	}
																	label="Wed:"
																/>
																<HoursLine
																	open={
																		location.details
																			.locationHours
																			.thursdayOpen
																	}
																	close={
																		location.details
																			.locationHours
																			.thursdayClose
																	}
																	label="Thu:"
																/>
																<HoursLine
																	open={
																		location.details
																			.locationHours
																			.fridayOpen
																	}
																	close={
																		location.details
																			.locationHours
																			.fridayClose
																	}
																	label="Fri:"
																/>
																<HoursLine
																	open={
																		location.details
																			.locationHours
																			.saturdayOpen
																	}
																	close={
																		location.details
																			.locationHours
																			.saturdayClose
																	}
																	label="Sun:"
																/>
															</>
														) : (
															<></>
														)}

														<Link
															gatsby={false}
															css={css`
																display: block;
																margin-top: 10px;
															`}
															color={
																theme.colors.primary.primaryBrand
															}
															to={location.link}
														>
															{staticContent.viewLocationDetails}
														</Link>
													</Typography>
												</Expander>
											</ExpansionBox>
										</LocationListItemText>
									</LocationsListItem>
								) : (
									<React.Fragment key={index}></React.Fragment>
								);
							})}
						</LocationsList>
					</LeftPane>
				</ContentSection>
				<MobileOverlay
					css={css`
						background-color: ${theme.colors.primary.background};
						left: ${addMyLocationOpen ? "0px" : "-100%"};
					`}
				>
					<MobileOverlayHeader
						css={css`
							background-color: ${theme.colors.primary.darkGray};
						`}
					>
						<MobileOverlayHeaderClose
							css={css`
								border-left: 1px solid ${theme.colors.primary.gray};
							`}
							onClick={() => {
								setAddMyLocationOpen(false);
								const body = document?.querySelector("body");
								body && body.setAttribute("style", bodyStyle ?? "");
							}}
						>
							<Icon
								icon="close"
								size="xs"
								color={theme.colors.primary.white}
								css={css`
									cursor: pointer;
								`}
							/>
						</MobileOverlayHeaderClose>
					</MobileOverlayHeader>
					<MobileOverlayBody
						css={css`
							padding: 0px;
							padding-bottom: 60px;
						`}
					>
						<ContentSection vPadding="15px" hPadding="20px" type="primary">
							<Typography
								variant="h4"
								css={css`
									font-size: 20px;
									margin-bottom: 20px;
								`}
							>
								{staticContent.addMyLocation}
							</Typography>
							<FindALocation
								css={css`
									width: calc(100% - 130px);
								`}
							>
								<Typography
									variant="div"
									css={css`
										display: block;
										width: 100%;
										margin-bottom: 9px;
									`}
									type="primary"
									size="md"
								>
									{staticContent.findALocation}
								</Typography>
								<LocationInput
									impRef={addMyLocationInputRef}
									onLocationSelected={(lat, lng, title) => {
										updateUnsavedLocation("lat", lat);
										updateUnsavedLocation("lng", lng);
										updateUnsavedLocation("title", title);
									}}
								/>
							</FindALocation>
							<Within
								css={css`
									width: 120px;
								`}
							>
								<Typography
									variant="div"
									css={css`
										display: block;
										width: 100%;
										margin-bottom: 9px;
									`}
									type="primary"
									size="md"
								>
									{staticContent.within}
								</Typography>
								<Dropdown
									shape="rounded"
									value={unsavedLocation.within}
									options={[
										{ display: "25 miles", value: "25" },
										{ display: "50 miles", value: "50" },
										{ display: "100 miles", value: "100" },
										{ display: "250 miles", value: "250" },
										{ display: "500 miles", value: "500" },
										{ display: "1000 miles", value: "1000" },
									]}
									onChange={(val) => {
										updateUnsavedLocation("within", Number(val));
									}}
									language={language}
								/>
							</Within>
							<Within
								css={css`
									width: calc(100% - 80px);
									margin-left: 0px;
								`}
							>
								<Typography
									variant="div"
									css={css`
										display: block;
										width: 100%;
										margin-bottom: 9px;
									`}
									type="primary"
									size="md"
								>
									{staticContent.customLabel}
								</Typography>
								<Input
									language={language}
									inputType="text"
									shape="rounded"
									value={unsavedLocation.label}
									onChange={(e) => {
										updateUnsavedLocation("label", e.target.value);
									}}
								/>
							</Within>
							<Within
								css={css`
									width: 70px;
								`}
							>
								<Typography
									variant="div"
									css={css`
										display: block;
										width: 100%;
										margin-bottom: 9px;
									`}
									type="primary"
									size="md"
								>
									{staticContent.color}
								</Typography>
								<ColorPicker
									onChange={(color) => {
										updateUnsavedLocation("color", color);
									}}
									value={unsavedLocation.color}
								/>
							</Within>
							<FauxButtonLink
								css={css`
									display: block;
									width: 100%;
									margin-top: 24px;
								`}
								onClick={() => {
									if (navigator.geolocation) {
										navigator.geolocation.getCurrentPosition(
											(position: any) => {
												if (
													addMyLocationInputRef &&
													addMyLocationInputRef.current
												) {
													addMyLocationInputRef.current.setValue(
														String(position.coords.latitude) +
															", " +
															String(position.coords.longitude)
													);
												}
												updateUnsavedLocation(
													"lat",
													position.coords.latitude
												);
												updateUnsavedLocation(
													"lng",
													position.coords.longitude
												);
												updateUnsavedLocation("title", "My Location");
											},
											(error) => {
												setGeolocationMessage(
													"Use My Location feature unavailable without allowing geolocation permission."
												);
												setGeolocationShow(true);
											}
										);
									} else {
										setGeolocationMessage(
											"Use My Location feature unavailable in browsers without geolocation features."
										);
										setGeolocationShow(true);
									}
								}}
							>
								<FauxIcon
									css={css`
										background-color: ${theme.colors.primary.primaryBrand};
									`}
								>
									<Icon
										icon="location"
										color={theme.colors.primary.white}
										size="xs"
									/>
								</FauxIcon>
								<Typography
									variant="span"
									color={theme.colors.primary.header}
									weight="bold"
									size="sm"
									css={css`
										vertical-align: middle;
										line-height: 16px;
									`}
								>
									{staticContent.useCurrentLocation}
								</Typography>
							</FauxButtonLink>
							<Within
								css={css`
									width: 100%;
									padding-top: 30px;
									margin-left: 0px;
								`}
							>
								<Button
									type="secondary"
									onClick={() => {
										addUnsavedLocation();
									}}
									fullwidth={true}
									shape="rounded"
									size="sm"
									label={staticContent.add}
								/>
							</Within>
						</ContentSection>
						<ContentSection type="primary" vPadding="15px" hPadding="0px">
							<UnsavedLocations
								css={css`
									height: auto;
									overflow: hidden;
									padding-top: 0px;
								`}
							>
								{unsavedLocations.map((location: any, index: number) => {
									return (
										<ResultContainer
											css={css`
												margin-bottom: 0px;
												position: relative;
												padding-top: 40px;
												padding-left: 20px;
												padding-right: 20px;
												padding-bottom: 15px;
												box-sizing: border-box;
												background-color: ${index % 2 === 0
													? theme.colors.primary.lighterGray
													: theme.colors.primary.white};
											`}
											key={index}
										>
											<FauxIcon
												onClick={() => {
													removeUnsavedLocation(index);
												}}
												css={css`
													background-color: ${theme.colors.primary.gray};
													margin-right: 0px;
													display: block;
													width: 20px;
													height: 20px;
													border-radius: 20px;
													line-height: 22px;
													position: absolute;
													right: 15px;
													margin-right: 0px;
													top: 15px;
												`}
											>
												<Icon
													icon="close"
													size="xs"
													color={theme.colors.primary.white}
												/>
											</FauxIcon>
											<FindALocation
												css={css`
													width: calc(100% - 130px);
												`}
											>
												<Typography
													variant="div"
													css={css`
														display: block;
														width: 100%;
														line-height: 40px !important;
													`}
													type="primary"
													size="md"
													weight="bold"
													color={theme.colors.primary.header}
												>
													{location.title}
												</Typography>
											</FindALocation>
											<Within
												css={css`
													width: 120px;
												`}
											>
												<Dropdown
													shape="rounded"
													value={location.within}
													options={[
														{ display: "25 miles", value: "25" },
														{ display: "50 miles", value: "50" },
														{ display: "100 miles", value: "100" },
														{ display: "250 miles", value: "250" },
														{ display: "500 miles", value: "500" },
														{ display: "1000 miles", value: "1000" },
													]}
													onChange={(val) => {
														updateUnsavedLocations(
															index,
															"within",
															Number(val)
														);
													}}
													language={language}
												/>
											</Within>
											<Within
												css={css`
													width: calc(100% - 80px);
													margin-left: 0px;
												`}
											>
												<Input
													language={language}
													inputType="text"
													shape="rounded"
													value={location.label}
													onChange={(e) => {
														let newLabel = e.target.value;
														updateUnsavedLocations(
															index,
															"label",
															newLabel
														);
													}}
												/>
											</Within>
											<Within
												css={css`
													width: 70px;
												`}
											>
												<ColorPicker
													onChange={(color) => {
														updateUnsavedLocations(
															index,
															"color",
															color
														);
													}}
													value={location.color}
												/>
											</Within>
										</ResultContainer>
									);
								})}
							</UnsavedLocations>
						</ContentSection>

						<ButtonContainer
							css={css`
								position: absolute;
								bottom: 0px;
								height: 60px;
								padding-top: 10px;
								box-sizing: border-box;
								left: 0px;
							`}
						>
							<ButtonContain>
								<Button
									label="Cancel"
									paddingType="relaxed"
									size="sm"
									type="secondary"
									shape="rounded"
									variant="outline"
									onClick={() => {
										setAddMyLocationOpen(false);
										const body = document?.querySelector("body");
										body && body.setAttribute("style", bodyStyle ?? "");
									}}
								/>
							</ButtonContain>
							<ButtonContain>
								<Button
									paddingType="relaxed"
									size="sm"
									shape="rounded"
									type="secondary"
									label={staticContent.save}
									onClick={() => {
										setSavedLocations(unsavedLocations);
										setAddMyLocationOpen(false);
										refreshMapLocations(null, unsavedLocations);
										const body = document?.querySelector("body");
										body && body.setAttribute("style", bodyStyle ?? "");
									}}
								/>
							</ButtonContain>
						</ButtonContainer>
					</MobileOverlayBody>
				</MobileOverlay>
				<MobileOverlay
					css={css`
						background-color: ${theme.colors.secondary.background};
						left: ${showMobileFilters ? "0px" : "-100%"};
					`}
				>
					<MobileOverlayHeader
						css={css`
							background-color: ${theme.colors.primary.darkGray};
						`}
					>
						<MobileOverlayHeaderClose
							css={css`
								border-left: 1px solid ${theme.colors.primary.gray};
							`}
							onClick={() => {
								setShowMobileFilters(false);
								const body = document?.querySelector("body");
								body && body.setAttribute("style", bodyStyle ?? "");
							}}
						>
							<Icon
								icon="close"
								size="xs"
								color={theme.colors.primary.white}
								css={css`
									cursor: pointer;
								`}
							/>
						</MobileOverlayHeaderClose>
					</MobileOverlayHeader>
					<MobileOverlayBody>
						<ContentSection vPadding="0" hPadding="0" type="secondary">
							{tempFiltersSelected.length > 0 ? (
								<>
									<Typography
										css={css`
											position: relative;
											height: 24px;
											line-height: 24px !important;
											margin-bottom: 24px;
										`}
										variant="div"
										weight="bold"
										color={theme.colors.primary.header}
										size="lg"
									>
										Selected Filters:
										<div
											css={css`
												display: block;
												position: absolute;
												top: 0px;
												right: 0px;
												width: 112px;
											`}
											onClick={() => {
												setTempFiltersSelected([]);
											}}
										>
											<Typography
												variant="div"
												css={css`
													position: absolute;
													left: 0;
													top: 0;
													height: 24px;
													width: 24px;
													text-align: center;
													line-height: 23px !important;
													box-sizing: border-box;
													border-radius: 24px;
													border: 1px solid ${theme.colors.primary.link};
												`}
											>
												<Icon
													icon="close"
													size="xs"
													color={theme.colors.primary.link}
												/>
											</Typography>
											<Typography
												variant="div"
												css={css`
													position: absolute;
													right: 0;
													top: 0;
													height: 24px;
													line-height: 24px !important;
												`}
												size="md"
												color={theme.colors.primary.link}
											>
												Clear Filters
											</Typography>
										</div>
									</Typography>
								</>
							) : (
								<></>
							)}
							<Typography
								variant="div"
								css={css`
									display: block;
									width: 100%;
									height: auto;
								`}
							>
								{tempFiltersSelected.map((item: any, index: number) => {
									return (
										<MobileFilterBadge
											key={index}
											css={css`
												background-color: ${theme.colors.primary
													.background};
											`}
										>
											{getFilterItemLanguage(item, true)}
											<FilterBadgeIcon
												css={css`
													top: 0px;
												`}
											>
												<Icon
													icon="close"
													size="xs"
													onClick={() => {
														changeTempFilters(item, false);
													}}
												/>
											</FilterBadgeIcon>
										</MobileFilterBadge>
									);
								})}
							</Typography>
							<MenuDivider
								color={theme.colors.primary.darkGray}
								css={css`
									margin-top: 20px;
									margin-bottom: -1px;
								`}
							/>
						</ContentSection>
						<Accordion variant="line" multiple={true}>
							<AccordionTab
								header={staticContent.metalShape}
								circled={true}
								defaultOpen={true}
								maxHeight="5000px"
							>
								{MaterialFilters.map((filter: any, index: number) => {
									return (
										<React.Fragment key={index}>
											<Typography
												variant="div"
												size="lg"
												css={css`
													margin-top: 35px;
												`}
												color={theme.colors.primary.header}
											>
												{LanguageFilters[language][filter.title]}
											</Typography>
											<MenuDivider
												color={theme.colors.primary.darkGray}
												css={css`
													margin-top: 9px;
													margin-bottom: 10px;
												`}
											/>
											{filter.filters.map((item: any, index: number) => {
												return (
													<Checkbox
														key={index}
														language={language}
														value={item}
														label={LanguageFilters[language][item]}
														size="sm"
														checked={
															tempFiltersSelected.indexOf(
																filter.title + ": " + item
															) !== -1
														}
														onChange={(val, checked) => {
															changeTempFilters(
																filter.title + ": " + item,
																checked
															);
														}}
													/>
												);
											})}
										</React.Fragment>
									);
								})}
							</AccordionTab>
							<AccordionTab
								header={staticContent.processing}
								circled={true}
								maxHeight="5000px"
							>
								{ProcessFilters.map((filter: any, index: number) => {
									return (
										<React.Fragment key={index}>
											<Typography
												variant="div"
												size="lg"
												css={css`
													margin-top: 35px;
												`}
												color={theme.colors.primary.header}
											>
												{LanguageFilters[language][filter.title]}
											</Typography>
											<MenuDivider
												color={theme.colors.primary.darkGray}
												css={css`
													margin-top: 9px;
													margin-bottom: 10px;
												`}
											/>
											{filter.filters.map((item: any, index: number) => {
												return (
													<Checkbox
														key={index}
														language={language}
														value={item}
														label={LanguageFilters[language][item]}
														size="sm"
														checked={
															tempFiltersSelected.indexOf(
																filter.title + ": " + item
															) !== -1
														}
														onChange={(val, checked) => {
															changeTempFilters(
																filter.title + ": " + item,
																checked
															);
														}}
													/>
												);
											})}
										</React.Fragment>
									);
								})}
							</AccordionTab>
							<AccordionTab
								header={staticContent.locationType}
								circled={true}
								maxHeight="2000px"
							>
								<>
									<Spacer
										css={css`
											height: 34px;
										`}
									/>
									{LocationTypeFilters.map((item: any, index: number) => {
										return (
											<Checkbox
												key={index}
												language={language}
												value={item}
												label={LanguageFilters[language][item]}
												size="sm"
												checked={
													tempFiltersSelected.indexOf(
														"Location Type: " + item
													) !== -1
												}
												onChange={(val, checked) => {
													changeTempFilters(
														"Location Type: " + item,
														checked
													);
												}}
											/>
										);
									})}
								</>
							</AccordionTab>
						</Accordion>
					</MobileOverlayBody>
					<ContentSection type="primary" vPadding="10px" hPadding="20px">
						<Button
							type="secondary"
							fullwidth={true}
							shape="square"
							size="sm"
							label="Apply"
							onClick={() => {
								setFiltersSelected(tempFiltersSelected);
								setShowMobileFilters(false);
								const body = document?.querySelector("body");
								body && body.setAttribute("style", bodyStyle ?? "");
							}}
						/>
					</ContentSection>
				</MobileOverlay>
			</Media>
			<Modal
				title="Geolocation Problem"
				closable={false}
				isOpen={geolocationShow}
				onClose={() => {
					setGeolocationShow(false);
				}}
				type="primary"
				bodyType="primary"
				modalStyle="default"
				buttonArea={
					<Button
						label={"Okay"}
						onClick={() => {
							setGeolocationShow(false);
						}}
					/>
				}
			>
				<Typography variant="div" type="primary" size="md">
					{geolocationMessage}
				</Typography>
			</Modal>
		</>
	);
};

export default Landing;
